import { React, useEffect, useState } from 'react';
import './Footer.css';
import { Col, Row } from "react-bootstrap";
import logo from '../images/logo-rambuilders.svg';
import bottomicon from '../images/footer-bottom.png';
import { enqueueSnackbar } from 'notistack';
import axios from 'axios';
import { config } from '../Config/Config';
import { useNavigate } from 'react-router-dom';

const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
const validPhoneNoRegex = RegExp(
    /^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/i
);
export default function Footer() {
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState('');
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [errors, setErrors] = useState({});
    useEffect(() => {
        // console.log("d")
    }, []);

    const requestForDemo = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        } else {
            let headers = {
                "Content-Type": 'application/json',
                // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
                "applicationType": "web",
                "branchId": config.branchId
            }
            var payload = {
                "companyName": companyName,
                "firstName": contactName,
                "email": email,
                "mobile": mobile,
            }
            console.log("payload", payload)
            axios.post(config.apiUrl + 'manager/addDemoRequest', payload, { headers: headers })
                .then(function (response) {
                    console.log(response);
                    if (response.data.status == true) {
                        console.log("success", response.data.message, payload);
                        enqueueSnackbar("Thank you for your interest, Entropi team will connect soon!", { variant: "success" })
                        sendEmailRequest(response.data.duplicate, payload.firstName, payload.email)
                    }
                    setCompanyName('')
                    setContactName('')
                    setEmail('')
                    setMobile('')
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                    let errorMsg = "Something went wrong"
                    if (error.code === "ERR_NETWORK")
                        errorMsg = "Too many requests from this IP"
                    else if (error?.response?.data?.message)
                        errorMsg = error?.response?.data?.message
                    enqueueSnackbar(errorMsg, { variant: "error" })
                    setCompanyName('')
                    setContactName('')
                    setEmail('')
                    setMobile('')

                });
        }
    };
    const sendEmailRequest = (duplicate, contactName, email) => {
        let headers = {
            "Content-Type": 'application/json',
            // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "duplicate": duplicate,
            "firstName": contactName,
            "email": email
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/sendEmailRequest', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!contactName) {
            errorObj = {
                ...errorObj,
                contactNameError: true
            };
            error = true;
        }
        if (!validEmailRegex.test(email)) {
            errorObj = {
                ...errorObj,
                emailError: true
            }
            error = true;
        }
        if (!validPhoneNoRegex.test(mobile)) {
            errorObj = {
                ...errorObj,
                mobileError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <div className='mt-3 footer_bg'>
            <Row style={{ width: "100%" }}>
                <Col xs={12} md={2}>
                    <img src={logo} className="logo-f" /><br />
                    {/* <div className='copy-text'>Copyright 2023 Entropi. All rights reserved.</div> */}
                </Col>
                <Col xs={12} md={10}>
                    <Row style={{ width: "100%" }}>
                        <Col xs={12} md={4}>
                            <div className='ml-3 mt-4'>
                                <a href={config.frontendUrl + "faq"} target="_blank" rel="noopener noreferrer"> <p className='py-3 text-base text-gray-50 font-semibold'>FAQ</p></a>
                                <a href={config.frontendUrl + "dp"} target="_blank" rel="noopener noreferrer"> <p className='py-3 text-base text-gray-50 font-semibold'>Privacy Policy</p></a>
                                <a href={config.frontendUrl + "tnc"} target="_blank" rel="noopener noreferrer"> <p className='py-3 text-base text-gray-50 font-semibold'>Terms & Conditions</p></a>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className='ml-3 mt-4 text-base text-gray-50 font-semibold'>Enquire for more details</div>
                            <div className='ml-3'>
                                <input className={`text-sm w-full px-2 py-1 ${errors.companyNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-3`}
                                    type="text"
                                    onChange={(e) => { setCompanyName(e.target.value) }}
                                    name="companyName"
                                    value={companyName}
                                    placeholder="Company Name" />
                                <input className={`text-sm w-full px-2 py-1 ${errors.contactNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                                    type="text"
                                    onChange={(e) => { setContactName(e.target.value); setErrors({ ...errors, contactNameError: false }) }}
                                    name="contactName"
                                    value={contactName}
                                    placeholder="Name" />
                                <input className={`text-sm w-full px-2 py-1 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                                    type="email"
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value); setErrors({ ...errors, emailError: false }) }}
                                    name="email"
                                    placeholder="Email" />
                                <input className={`text-sm w-full px-2 py-1 ${errors.mobileError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                                    type="text"
                                    value={mobile}
                                    onChange={(e) => { setMobile(e.target.value); setErrors({ ...errors, mobileError: false }) }}
                                    name="mobile"
                                    placeholder="Mobile" />
                            </div>
                            <div className='flex justify-end mt-3 mb-2 w-full'>
                                <button className=' border-[1px] bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-1 font-semibold uppercase rounded text-xs'
                                    onClick={requestForDemo} >Enquire</button>
                            </div>
                        </Col>
                        <Col xs={12} md={1}></Col>
                        <Col xs={12} md={3}>
                            <img src={bottomicon} className="morphine-img" />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <footer className='text-white text-xs text-center'>Copyright 2023. All rights reserved.</footer>
        </div>
    )
}
