import React, { useState } from 'react';
import logo from '../../images/logo-rambuilders.svg';
import threadality from '../../images/threadality-logo.svg'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

const TopBar1 = () => {
    const navigate = useNavigate();
return (
    <>
        <div className="sticky top-0 z-50 flex min-w-[100vw] h-12 items-center justify-left" style={{ color: "black", background: "#ebf1ff" }}>
            <div className='justify-left items-center ml-3 sm:w-13 md:w-11 w-20 cursor-pointer'><Link to="/"><img src={logo} /></Link></div>
            <div className=" md:text-2xl sm:text-2xl font-semibold w-[100%] text-center items-center">
            </div>
            <div className='ml-2 mr-4 w-28 sm:w-20 cursor-pointer'> <img src={threadality} /></div>
        </div>

    </>
)
}

export default TopBar1;