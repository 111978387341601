import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { enqueueSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import logo from '../../images/logo-rambuilders.svg'

const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
const validPhoneNoRegex = RegExp(
    /^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/i
    // /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/i
);
export default function Popup({ showPopup, closePopup }) {
    const navigate = useNavigate();
    const [companyName, setCompanyName] = useState('');
    const [contactName, setContactName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [errors, setErrors] = useState({});
    useEffect(() => {
        // console.log("d")
    }, []);

    const requestForDemo = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields with correct data!', { variant: "error" })
            return
        } else {
            let headers = {
                "Content-Type": 'application/json',
                // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
                "applicationType": "web",
                "branchId": config.branchId
            }
            var payload = {
                "companyName": companyName,
                "firstName": contactName,
                "email": email,
                "mobile": mobile,
            }
            console.log("payload", payload)
            axios.post(config.apiUrl + 'manager/addDemoRequest', payload, { headers: headers })
                .then(function (response) {
                    console.log(response);
                    if (response.data.status === true) {
                        console.log("success", response.data.message, payload);
                        enqueueSnackbar("Thank you for your interest, Entropi team will connect soon!", { variant: "success" })
                        sendEmailRequest(response.data.duplicate, payload.firstName, payload.email)
                    }
                    closePopup()
                    setCompanyName('')
                    setContactName('')
                    setEmail('')
                    setMobile('')
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                    let errorMsg = "Something went wrong"
                    if (error.code === "ERR_NETWORK")
                        errorMsg = "Too many requests from this IP"
                    else if (error?.response?.data?.message)
                        errorMsg = error?.response?.data?.message
                    enqueueSnackbar(errorMsg, { variant: "error" })
                    closePopup()
                    // navigate("*")
                });
        }
    };
    const sendEmailRequest = (duplicate, contactName, email) => {
        let headers = {
            "Content-Type": 'application/json',
            // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "duplicate": duplicate,
            "firstName": contactName,
            "email": email
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/sendEmailRequest', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!contactName) {
            errorObj = {
                ...errorObj,
                contactNameError: true
            };
            error = true;
        }
        if (!validEmailRegex.test(email)) {
            errorObj = {
                ...errorObj,
                emailError: true
            }
            error = true;
        }
        if (!validPhoneNoRegex.test(mobile)) {
            errorObj = {
                ...errorObj,
                mobileError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <Modal show={showPopup} size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>

                <div className='px-4 w-full flex items-center justify-between'>
                    <div className='text-base text-gray-800 font-semibold'>Enquire for more details</div>
                    <div> <i onClick={closePopup} className="cursor-pointer fa fa-times" aria-hidden="true"></i></div>
                </div>

            </Modal.Header>
            <Modal.Body>
                <div className='flex flex-col gap-4 justify-center items-center'>
                    <img src={logo} className='w-[20%]' />
                    <div className="text-base text-gray-800 font-bold">Entropi</div>
                    <Row style={{ width: "100%" }}>
                        <Col xs={12} md={12}>
                            <div className='ml-3'>
                                <input className={`text-sm w-full px-2 py-2 ${errors.companyNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-1`}
                                    type="text"
                                    onChange={(e) => { setCompanyName(e.target.value) }}
                                    name="companyName"
                                    value={companyName}
                                    placeholder="Company Name" />
                                <p className={`h-4 ${true ? "invisible" : "invisible"}`}>a</p>
                                <input className={`text-sm w-full px-2 py-2 ${errors.contactNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                    type="text"
                                    onChange={(e) => { setContactName(e.target.value); setErrors({ ...errors, contactNameError: false }) }}
                                    name="contactName"
                                    value={contactName}
                                    placeholder="Name" />
                                <p className={`h-4 ${errors.contactNameError ? "text-[10px] text-red-500" : "invisible"}`}>Please enter contact name</p>
                                <input className={`text-sm w-full px-2 py-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                    type="email"
                                    value={email}
                                    onChange={(e) => { setEmail(e.target.value); setErrors({ ...errors, emailError: false }) }}
                                    name="email"
                                    placeholder="Email" />
                                <div className={`h-4 ${errors.emailError ? "text-[10px] text-red-500" : "invisible"}`}>Invalid Email</div>
                                <input className={`text-sm w-full px-2 py-2 ${errors.mobileError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                    type="text"
                                    value={mobile}
                                    onChange={(e) => { setMobile(e.target.value); setErrors({ ...errors, mobileError: false }) }}
                                    name="mobile"
                                    placeholder="Mobile" />
                                <div className={`h-4 ${errors.mobileError ? "text-[10px] text-red-500" : "invisible"}`}>Invalid mobile number</div>
                            </div>
                            <div className='flex justify-end mb-2 w-full'>
                                <button className=' border-[1px] bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs'
                                    onClick={requestForDemo} >Enquire</button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
        </Modal>
    )
}
