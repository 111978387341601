import React, { useState } from 'react';
import logo from '../../images/logo-rambuilders.svg';
import threadality from '../../images/threadality-logo.svg'
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from './Popup';
import Services from './Services';

const TopBar = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showServicesPopup, setShowServicesPopup] = useState(false);
    const togglePopup = () => {
        setShowPopup(!showPopup)
    }
    const handleNavigate = () => {
        navigate('/services')
    }
    const handleNavigateToRegister = () => {
        navigate('/register')
    }
    const toggleServices = () => {
        setShowServicesPopup(!showServicesPopup)
    }
    return (
        <>
            {showPopup && <Popup showPopup={showPopup} setShowPopup={setShowPopup} closePopup={togglePopup} />}
            {showServicesPopup && <Services showServicesPopup={showServicesPopup} setShowServicesPopup={setShowServicesPopup} closePopup={toggleServices} />}
            <div className="sticky top-0 z-50 flex min-w-[100vw] h-12 items-center justify-left" style={{ color: "black", background: "#ebf1ff" }}>
                <div className='justify-left items-center ml-3 w-24 cursor-pointer'><Link to="/"><img src={logo} /></Link></div>
                <div className=" md:text-2xl sm:text-2xl font-semibold w-[100%] text-center items-center">
                </div>
                <>
                    <div className='hidden md:flex '>
                        <button className='mr-4 border-[1px] bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs'
                            onClick={handleNavigateToRegister}>Register&nbsp;&nbsp;with&nbsp;&nbsp;Entropi</button>
                        <button className='mr-4 border-[1px] bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs'
                            onClick={toggleServices} >Entropi&nbsp;&nbsp;Directory&nbsp;&nbsp;Services</button>
                        <button className='mr-20 invisible border-[1px] bg-custom-blue hover:bg-custom-blue text-black px-4 py-2 font-semibold uppercase rounded text-xs'
                            onClick={togglePopup}>Enquire&nbsp;&nbsp;About&nbsp;&nbsp;Entropi</button>
                        <div className='ml-2 mr-4 w-28 sm:w-20 cursor-pointer'> <img src={threadality} /></div>
                    </div>
                    <div className="md:hidden flex">
                        <div className='mr-4 w-20 sm:w-20 cursor-pointer'> <img src={threadality} /></div>
                        <button className="text-black" onClick={() => setMenuOpen(!isMenuOpen)}>
                            {isMenuOpen ? (
                                // Close icon
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                // Menu icon

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16m-7 6h7"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                    {isMenuOpen && (
                        <div className="w-full flex flex-col items-center justify-around md:hidden absolute top-12 right-0 bg-gray-100 p-2">
                            <button className='border-[1px] bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 font-semibold rounded text-xs'
                                onClick={handleNavigateToRegister}>Register&nbsp;&nbsp;with&nbsp;&nbsp;Entropi</button>
                            <button className='my-1 first-line:border-[1px] bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 font-semibold rounded text-xs'
                                onClick={toggleServices} >Entropi&nbsp;&nbsp;Directory&nbsp;&nbsp;Services</button>
                            <button className='border-[1px] hidden bg-custom-blue hover:bg-custom-blue text-black p-2 font-semibold rounded text-xs'
                                onClick={togglePopup}>Enquire&nbsp;&nbsp;About&nbsp;&nbsp;Entropi</button>
                        </div>
                    )}

                </>

            </div>

        </>
    )
}

export default TopBar;