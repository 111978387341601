import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { config } from "../../Config/Config";
import moment from "moment-timezone";
import { downloadICSFile, generateICSFile } from "./utils";
import download from "../../images/download-icon.svg"

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const EditEvent = ({ data, closePopup, queryEmail, meetingTypes, statusArr, getAllMeetings, empDetails }) => {
    const [selectedStatus, setSelectedStatus] = useState();
    const [meetStatus, setMeetStatus] = useState([])

    useEffect(() => {
        console.log("data", data, data.meeting_status);
        let startTime = new Date(data.start_time);
        let now = new Date();
        let curMeetingStatus = [...statusArr];
        if (startTime < now) {
            curMeetingStatus = curMeetingStatus.filter(type => type.id === 3 || type.id === 4);
        }
        else if (startTime > now) {
            curMeetingStatus = curMeetingStatus.filter(type => type.id === 1 || type.id === 2 || type.id === 5);
        }
        setMeetStatus(curMeetingStatus);
        setSelectedStatus(data.meeting_status)
    }, []);

    const editMeetingStatus = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "meetingStatus": selectedStatus,
            "id": data.id
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/updateMeetingStatus', payload, { headers: headers })
            .then(function (response) {
                console.log("success", response.data);
                getAllMeetings();
                closePopup();
            })
            .catch(function (error) {
                console.log("error", error, payload);
            });
    };
    const getReason = (id) => {
        let curReason = meetingTypes && meetingTypes.find(data => data.id === id);
        return curReason?.reason
    }
    const dateFormatter = (date) => {
        if (!date) return "";
        let formattedDate = moment(date).format("DD/MM/YYYY hh:mma");
        return formattedDate
    };
    const getCurStatus = (id) => {
        if (!id) return "Meeting Status";
        let curStatus = statusArr && statusArr.find(data => data.id == id);
        console.log("idd", id, statusArr, curStatus)
        return curStatus?.status
    };
    const handleDownloadICS = (data) => {
        let startTime = new Date(data.start_time);
        let endTime = new Date(data.end_time);
        const meetingDetails = {
            startYear: startTime.getFullYear(),
            startMonth: startTime.getMonth() + 1,
            startDay: startTime.getDate(),
            startHour: startTime.getHours(),
            startMinute: startTime.getMinutes(),
            endYear: endTime.getFullYear(),
            endMonth: endTime.getMonth() + 1,
            endDay: endTime.getDate(),
            endHour: endTime.getHours(),
            endMinute: endTime.getMinutes(),
            title: data.title,
            description: data.description,
            location: 'Online-Web Meet',
        };
        const icsContent = generateICSFile(meetingDetails);

        if (icsContent) {
            downloadICSFile(icsContent, 'meeting.ics');
        }
    };
    return (
        <div>
            <Modal
                show={true}
                onHide={closePopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <div className="max-w-lg min-w-[25vw] m-2 mr-2 mx-auto mb-4">
                    <div className="flex items-center w-full justify-between">
                        <label className="mr-1 text-lg text-black-600 w-2/3 text-end">Edit Meeting Status</label>
                        <OverlayTrigger placement="right"
                            overlay={<Tooltip>
                                <p>Download ICS file</p>
                            </Tooltip>}>
                            <img src={download} alt="" className="w-6 h-6 cursor-pointer" onClick={() => handleDownloadICS(data)} />
                        </OverlayTrigger>
                    </div>
                    <label className="mr-1 text-sm text-black-600">Name</label>
                    <p className={`text-sm w-full p-2 h-9 rounded border-[1px] border-gray-300`}
                        placeholder={queryEmail ? "Attendees Name" : "Organisers Name"}>{data.organisers_name === empDetails?.name ? data.attendees_name : data.organisers_name}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2">Email</label>
                    <p className={`text-sm w-full p-2 h-9 rounded border-[1px] border-gray-300`}
                        placeholder={queryEmail ? "Attendees Email" : "Organisers Email"}>{data.organisers_email === empDetails?.email ? data.attendees_email : data.organisers_email}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2">Start Time</label>
                    <p className="text-sm w-full p-2 h-9 border border-solid border-gray-300 rounded">{dateFormatter(data.start_time)}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2">End Time</label>
                    <p className="text-sm w-full p-2 h-9 border border-solid border-gray-300 rounded">{dateFormatter(data.end_time)}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2">Title</label>
                    <p className={`text-sm w-full p-2 h-9 border-[1px] border-gray-300 rounded resize-none`}
                        placeholder="Enter a title for the meeting">{data.title}</p>
                    <label className="mr-1 text-sm text-black-600 mt-2">Description</label>
                    <p className="text-sm w-full p-2 min-h-[2rem] border border-solid border-gray-300 rounded resize-none"
                        placeholder="Decribe the meeting agenda">{data.description}</p>
                    <div className="flex gap-4 mt-2 w-full">
                        <div className="flex flex-col w-1/2">
                            <label className="mr-1 text-sm text-black-600">Reason For Meeting</label>
                            <p className="text-sm w-full p-2 min-h-[2rem] border border-solid border-gray-300 rounded resize-none"
                                placeholder="Decribe the meeting agenda">{getReason(data.meeting_reason)}</p>
                        </div>
                        <div className="flex flex-col w-1/2">
                            <label className="mr-1 text-sm text-black-600">Staus</label>
                            <select name="status" value={selectedStatus} className="text-sm p-2 border border-solid border-gray-300 rounded"
                                onChange={(e) => setSelectedStatus(e.target.value)} >
                                <option value={selectedStatus ? selectedStatus : ""}>{selectedStatus ? getCurStatus(selectedStatus) : "Meeting Status"}</option>
                                {meetStatus && meetStatus.map(data => {
                                    return (
                                        <option key={data.id} value={data.id}>{data.status}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="flex justify-between text-center md:text-left">
                        <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs" type="submit" onClick={closePopup}>Back</button>
                        <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={editMeetingStatus} type="submit">Update</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default EditEvent