import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Modal, Form, OverlayTrigger, Tooltip, FormControl, Card, Button } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack'
import { config } from "../../Config/Config";
import { Link, useParams } from "react-router-dom";
import TermsAndConditions from "./TermsAndConditions";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const AdminDetails = (props) => {
    const navigate = useNavigate();
    const [uniqueCode, setUniqueCode] = useState('');
    const [checked, setChecked] = useState(false)
    const [showTerms, setShowTerms] = useState(false)
    const [errors, setErrors] = useState({});

    useEffect(() => {
        console.log("d")
        props.setRandomText(props.generateRandomText('employeeId'))
    }, []);
    const back = (e) => {
        e.preventDefault();
        props.prevStep();
        props.setRandomText(props.generateRandomText('companyName'))
    }
    const handleCheck = () => {
        setChecked(!checked)
    }
    const registerCompanyDetails = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        };
        if (props.inputValues.checked === false) {
            enqueueSnackbar('Please accept terms and conditions!', { variant: "error" })
        } else {
            let headers = {
                "Content-Type": 'application/json',
                // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
                "applicationType": "web"
            }
            let value = props.inputValues
            let encryptedEmail = simpleCrypto.encrypt(value.email);
            var payload = {
                "companyCode": value.companyCode,
                "name": value.companyName,
                "category": value.category,
                "duration": value.duration,
                "pan": value.pan,
                "logo": value.image,
                "userType": parseInt(value.userType),
                "registrationNo": value.registrationNo,
                "typeOfBusiness": parseInt(value.typeOfBusiness),
                "city": value.city,
                "location": value.location,
                "country": value.country,
                "status": 2,
                "sectorType": parseInt(value.companySize),
                "industryType": parseInt(value.industryType),
                "branchName": value.companyName,
                "branchCode": value.companyCode,
                "branchType": 4,
                "branchArea": 1,
                "employeeId": value.employeeId.toUpperCase(),
                "firstName": value.firstName,
                "lastName": value.lastName,
                "mobile": value.mobile,
                "email": encryptedEmail
            }
            console.log("payload", payload)
            axios.post(config.apiUrl + 'companyAdmin/registerCompanyDetails', payload, { headers: headers })
                .then(function (response) {
                    console.log(response);
                    if (response.data.status == true) {
                        console.log("success", response.data.message, payload);
                        enqueueSnackbar("Company Created Successfully", { variant: "success" })
                        setUniqueCode(response.data.newCompany.uniqueCode)
                        sendOtp(response.data.newCompany.uniqueCode)
                        handleNavigate()
                    } else {
                        console.log("Error", payload);
                    }
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                    navigate("*")
                });
        }
    };
    const sendOtp = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        let value = props.inputValues
        var payload = {
            "companyName": value.companyName,
            "email": value.email,
            "code": id || uniqueCode,
            "name": value.firstName,
            "mobile": value.mobile,
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/sendOtp', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                navigate("*")
            });
    };
    const handleNavigate = () => {
        navigate('/success')
    }
    const validateField = () => {
        console.log("values", props.inputValues)
        let error = false;
        let errorObj = {};
        if (!props.inputValues.firstName) {
            errorObj = {
                ...errorObj,
                firstNameError: true
            };
            error = true;
        }
        if (!props.inputValues.lastName) {
            errorObj = {
                ...errorObj,
                lastNameError: true
            };
            error = true;
        }
        if (!props.inputValues.email) {
            errorObj = {
                ...errorObj,
                emailError: true
            }
            error = true;
        }
        if (!props.inputValues.mobile) {
            errorObj = {
                ...errorObj,
                mobileError: true
            }
            error = true;
        }
        if (!props.inputValues.employeeId) {
            errorObj = {
                ...errorObj,
                employeeIdError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }

    return (
        <div className="md:w-1/3 max-w-sm mx-2 self-start">
            <div className="text-center md:text-left">
                <label className="mr-1 text-lg text-black-600">Company Admin Details</label>
            </div>
            <input className={`text-xs w-full px-4 py-2 ${errors.employeeIdError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                type="text"
                value={props.inputValues.employeeId}
                onChange={(e) => { props.handleChange(e); setErrors({ ...errors, employeeIdError: false }) }}
                name="employeeId"
                onFocus={(e) => props.handleFocus(e)}
                placeholder="Employee Id" />
            <input className={`text-xs w-full px-4 py-2 ${errors.firstNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                type="text"
                value={props.inputValues.firstName}
                onChange={(e) => { props.handleChange(e); setErrors({ ...errors, firstNameError: false }) }}
                name="firstName"
                onFocus={(e) => props.handleFocus(e)}
                placeholder="First Name" />
            <input className={`text-xs w-full px-4 py-2 ${errors.lastNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                type="text"
                onChange={(e) => { props.handleChange(e); setErrors({ ...errors, lastNameError: false }) }}
                name="lastName"
                value={props.inputValues.lastName}
                onFocus={(e) => props.handleFocus(e)}
                placeholder="Last Name" />
            <input className={`text-xs w-full px-4 py-2 ${errors.mobileError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                type="text"
                value={props.inputValues.mobile}
                onChange={(e) => { props.handleChange(e); setErrors({ ...errors, mobileError: false }) }}
                name="mobile"
                onFocus={(e) => props.handleFocus(e)}
                placeholder="Mobile (+NNMobile Number)" />
            <input className={`text-xs w-full px-4 py-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                type="email"
                value={props.inputValues.email}
                onChange={(e) => { props.handleChange(e); setErrors({ ...errors, emailError: false }) }}
                name="email"
                onFocus={(e) => props.handleFocus(e)}
                placeholder="Email" />

            {/* <div className="flex justify-end text-center md:text-left">
            <button className="mt-4 bg-black hover:bg-black px-4 py-2 text-white font-semibold uppercase rounded text-xs" onClick={updateCompany} type="submit">Add</button>
        </div> */}
            <div className="flex text-xs mt-3">
                <input type="checkbox" name="checked" value={props.inputValues.checked} onFocus={(e) => props.handleFocus(e)} checked={props.inputValues.checked} onChange={props.handleCheck} />
                <span className="ml-1 text-xs">I agree to the
                    <span className="text-sky-500 hover:text-sky-500 cursor-pointer" onClick={props.displayTerms}> Terms & Conditions </span>
                </span></div>
            <div className='flex justify-between mt-4 w-full'>
                <button className=' bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs'
                    onClick={back}>Prev</button>
                <button className=' bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs'
                    onClick={registerCompanyDetails} >Create</button>
            </div>
        </div>
    )
}

export default AdminDetails