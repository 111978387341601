import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { config } from '../../Config/Config';
import { useNavigate } from "react-router-dom";
import logo from '../../images/logo-rambuilders.svg';
import SimpleCrypto from "simple-crypto-js";
import { enqueueSnackbar } from 'notistack'
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const RegisterCompany = (props) => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const imgRef = useRef()

    useEffect(() => {
        console.log("companyTypes")
    }, []);

    // const handleNaviagate = (data) => {
    //     navigate('/addadmin', { state: { employee, data: data } })
    // }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!props.inputValues.companyName) {
            errorObj = {
                ...errorObj,
                companyNameError: true
            }
            error = true;
        }
        if (!props.inputValues.industryType) {
            errorObj = {
                ...errorObj,
                industryTypeError: true
            }
            error = true;
        }
        if (!props.inputValues.typeOfBusiness) {
            errorObj = {
                ...errorObj,
                typeOfBusinessError: true
            }
            error = true;
        }
        if (!props.inputValues.pan) {
            errorObj = {
                ...errorObj,
                panError: true
            }
            error = true;
        }
        if (!props.inputValues.registrationNo) {
            errorObj = {
                ...errorObj,
                registrationNoError: true
            }
            error = true;
        }
        if (!props.inputValues.category) {
            errorObj = {
                ...errorObj,
                categoryError: true
            }
            error = true;
        }
        if (!props.inputValues.location) {
            errorObj = {
                ...errorObj,
                locationError: true
            };
            error = true;
        }
        if (!props.inputValues.city) {
            errorObj = {
                ...errorObj,
                cityError: true
            };
            error = true;
        }
        if (!props.inputValues.country) {
            errorObj = {
                ...errorObj,
                countryError: true
            };
            error = true;
        }
        if (!props.inputValues.userType) {
            errorObj = {
                ...errorObj,
                userTypeError: true
            };
            error = true;
        }
        if (!props.inputValues.image) {
            errorObj = {
                ...errorObj,
                imageError: true
            };
            error = true;
        }
        if (!props.inputValues.companySize) {
            errorObj = {
                ...errorObj,
                companySizeError: true
            };
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }

    const saveAndContinue = (e) => {
        e.preventDefault();
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { className: 'bg-red-700' })
            return
        };
        props.nextStep();
        props.setRandomText(props.generateRandomText('companyName'))
    };
    return (
        <div className="md:w-1/3 max-w-sm mx-2 self-start">
            <div className="text-center md:text-left">
                <label className="text-lg text-black-600">Company Details</label>
            </div>
            <>
                <Row style={{ width: "100%" }} className="no-gutters">
                    <Col md={9} xs={12}>
                        <p className={`text-xs w-full px-4 py-2 border-[1px] border-gray-300 rounded mt-2`} >Head Office({props.inputValues.companyCode})</p>
                        <input className={`text-xs w-full px-4 py-2 ${errors.companyNameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            type="text"
                            onFocus={(e) => props.handleFocus(e)}
                            onChange={(e) => { props.handleChange(e); setErrors({ ...errors, companyNameError: false }) }}
                            name="companyName"
                            value={props.inputValues.companyName}
                            placeholder="Company Name" />

                    </Col>
                    <Col md={3} xs={12}>
                        <div style={{ marginLeft: "5px", marginRight: "5px", borderRadius: "8px", height: "75px", marginTop: "8px", textAlign: "center", objectFit: "contain" }} className={`${errors.imageError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"}`}>
                            {console.log("input", props.inputValues)}
                            <label htmlFor="upload-button">
                                {props.inputValues.image != "" ? (
                                    <OverlayTrigger placement="right" overlay={<Tooltip>Upload Logo</Tooltip>}>
                                        <div className="cursor-pointer"><img src={props.inputValues.image} style={{ marginTop: "1px", width: "72px", height: "72px" }} /></div>
                                    </OverlayTrigger>
                                ) : (
                                    <>
                                        <center><img src={logo} className="mt-1 w-12 cursor-pointer" /></center>
                                        <span className="text-[10px] cursor-pointer">Upload Logo</span>
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                id="upload-button"
                                name="image"
                                ref={imgRef}
                                onFocus={(e) => props.handleFocus(e)}
                                onChange={(e) => props.handleImage(e)}
                            />
                        </div>
                    </Col>
                </Row>
                <select name="industryType" value={props.inputValues.industryType} className={`text-xs w-full px-4 py-2  ${errors.industryTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.setInputValues({ ...props.inputValues, industryType: e.target.value }); setErrors({ ...errors, industryTypeError: false }) }}
                    onFocus={(e) => props.handleFocus(e)}>
                    <option value="">Industry Type</option>
                    {props.industryTypes && props.industryTypes.map(cType => {
                        return (
                            <option key={cType.id} value={cType.id}>{cType.industryType}</option>
                        )
                    })}
                </select>
                <select name="typeOfBusiness" value={props.inputValues.typeOfBusiness} className={`text-xs w-full px-4 py-2 ${errors.typeOfBusinessError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.setInputValues({ ...props.inputValues, typeOfBusiness: e.target.value }); setErrors({ ...errors, typeOfBusinessError: false }) }}
                    onFocus={(e) => props.handleFocus(e)}>
                    <option value={""}>{"Type of Business"}</option>
                    {props.companyTypes && props.companyTypes.map(cType => {
                        return (
                            <option key={cType.id} value={cType.id}>{cType.companyType}</option>
                        )
                    })}
                </select>
                <select name="companySize" value={props.inputValues.companySize} className={`text-xs w-full px-4 py-2 ${errors.companySizeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.setInputValues({ ...props.inputValues, companySize: e.target.value }); setErrors({ ...errors, companySizeError: false }) }}
                    onFocus={(e) => props.handleFocus(e)}>
                    <option value="">Sector Type</option>
                    {props.companySizes && props.companySizes.map(item =>
                        <option key={item.id} value={item.id}>{item.sectorType}</option>
                    )}
                </select>
                <select name="userType" value={props.inputValues.userType} className={`text-xs w-full px-4 py-2 ${errors.userTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.setInputValues({ ...props.inputValues, userType: e.target.value }); setErrors({ ...errors, userTypeError: false }) }}
                    onFocus={(e) => props.handleFocus(e)}>
                    <option value="">Clientele</option>
                    {props.userType && props.userType.map(cType => {
                        return (
                            <option key={cType.id} value={cType.id}>{cType.userType}</option>
                        )
                    })}
                </select>
                <input className={`text-xs w-full px-4 py-2 ${errors.locationError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    type="text"
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, locationError: false }) }}
                    name="location"
                    onFocus={(e) => props.handleFocus(e)}
                    value={props.inputValues.location}
                    placeholder="Location" />
                <input className={`text-xs w-full px-4 py-2 ${errors.cityError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    type="text"
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, cityError: false }) }}
                    name="city"
                    onFocus={(e) => props.handleFocus(e)}
                    value={props.inputValues.city}
                    placeholder="City" />
                <input className={`text-xs w-full px-4 py-2 ${errors.countryError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    type="text"
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, countryError: false }) }}
                    name="country"
                    onFocus={(e) => props.handleFocus(e)}
                    value={props.inputValues.country}
                    placeholder="Country" />
                <input className={`text-xs w-full px-4 py-2 ${errors.panError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    type="text"
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, panError: false }) }}
                    name="pan"
                    onFocus={(e) => props.handleFocus(e)}
                    value={props.inputValues.pan}
                    placeholder="Tax Number" />
                <input className={`text-xs w-full px-4 py-2 ${errors.registrationNoError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    type="text"
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, registrationNoError: false }) }}
                    name="registrationNo"
                    onFocus={(e) => props.handleFocus(e)}
                    value={props.inputValues.registrationNo}
                    placeholder="Company Registration" />
                <select name="category" value={props.inputValues.category} className={`text-xs w-full px-4 py-2 ${errors.categoryError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                    onChange={(e) => { props.handleChange(e); setErrors({ ...errors, categoryError: false }) }}
                    onFocus={(e) => props.handleFocus(e)} >
                    <option value={props.inputValues.category}>{props.inputValues.category === '' ? "Payment" : props.inputValues.category}</option>
                    <option key={1} value={"Free"}>Free</option>
                    {/* <option key={2} value={"Paid"}>Paid</option> */}
                </select>

                {props.inputValues.category === "Paid" ?
                    <div>
                        <select name="duration" value={props.inputValues.duration} className={`text-xs w-full px-4 py-2 ${errors.paidTypeError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded mt-2`}
                            onChange={(e) => { props.handleChange(e); setErrors({ ...errors, paidTypeError: false }) }}
                            onFocus={(e) => props.handleFocus(e)}>
                            <option value="">Paid Type</option>
                            {props.paidType.map(d => {
                                return (
                                    <option key={d.id} value={d.id}>{d.type}</option>
                                )
                            })}
                        </select>
                    </div>
                    : ""}
            </>
            <div className="flex justify-end text-center md:text-left">
                <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" onClick={saveAndContinue} type="submit">Next</button>
            </div>
        </div>
    )
}

export default RegisterCompany