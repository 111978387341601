import { Route, Routes } from 'react-router-dom';
import React from 'react';
import LandingPage from '../src/LandingPage/LandingPage';
import VerifyOtp from '../src/components/Register/VerifyOtp';
import MultiRegisterForm from '../src/components/Register/MultiRegisterForm';
import SuccessScreen from '../src/components/Register/SuccessScreen';
import TermsAndConditions from './components/Register/TermsAndConditions';
import OtpVerified from './components/Register/OtpVerified';
import PrivacyPolicy from './components/Register/PrivacyPolicy';
import FAQ from './components/Register/FAQ';
import PageNotFound from './LandingPage/PageNotFound';
import Calendar from './components/Calendar/Calendar';
import SearchScreen from './components/Register/SearchScreen';
import ResultsScreen from './components/Register/ResultsScreen';
import DirectoryFlow from './components/Register/DirectoryFlow';


const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/searchdirectoryservices" element={<SearchScreen />} />
            <Route path="/verifyotp" element={<VerifyOtp />} />
            <Route path="/:companyname/:id/verifyotp" element={<VerifyOtp />} />
            <Route path="/success" element={<SuccessScreen />} />
            <Route path="/register" element={<MultiRegisterForm />} />
            <Route path="/tnc" element={<TermsAndConditions />} />
            <Route path="/otpverified" element={<OtpVerified />} />
            <Route path="/dp" element={<PrivacyPolicy />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/directoryservices" element={<DirectoryFlow />} />
            <Route exact path="/results/:id/:id" element={<ResultsScreen />} />
            <Route path="/viewcalendar" element={<Calendar />} />
            <Route path="/newmeeting" element={<Calendar />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default AppRoutes;