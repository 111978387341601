import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../images/logo-rambuilders.svg'
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import lineone from '../../images/line 1.svg';
import linetwo from '../../images/line 2.svg';
import linethree from '../../images/line 3.svg';
import linefour from '../../images/line 4.svg';



const key = config.PASSWORD_ENCRYPTION_KEY
const simpleCrypto = new SimpleCrypto(key)


const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
const validPhoneNoRegex = RegExp(
    /^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$/i
    // /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/i
);
export default function Services({ showServicesPopup, closePopup }) {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [city, setCity] = useState('');
    const [errors, setErrors] = useState({});

    const handleNavigate = (user) => {
        closePopup()
        navigate('/searchdirectoryservices', { state: { user } })
    }
    const verifyUser = () => {
        if (validateField()) {
            enqueueSnackbar('Please fill all the necessary fields!', { variant: "error" })
            return
        } else {
            let headers = {
                "Content-Type": 'application/json',
                "Accept": 'application/json',
                "applicationType": "web",
            }
            var payload = {
                "name": name,
                "email": email,
                "phone": mobile,
                "city": city
            }
            console.log("payload", payload)
            axios.post(config.apiUrl + 'manager/verifyUserOnDirectoryService', payload, { headers: headers })
                .then(function (response) {
                    handleNavigate(response.data.user)
                })
                .catch(function (error) {
                    console.log("error", error, payload);
                    enqueueSnackbar(error?.response?.data?.message, { variant: "error" })
                    closePopup()
                });
        }
    };
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!name) {
            errorObj = {
                ...errorObj,
                nameError: true
            };
            error = true;
        }
        if (!validEmailRegex.test(email)) {
            errorObj = {
                ...errorObj,
                emailError: true
            }
            error = true;
        }
        if (!validPhoneNoRegex.test(mobile)) {
            errorObj = {
                ...errorObj,
                mobileError: true
            }
            error = true;
        }
        setErrors({ ...errorObj });
        return error;
    }
    return (
        <Modal show={showServicesPopup} size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>

                <div className='px-2 w-full flex items-center justify-between'>
                    <div className='flex text-base text-gray-800 font-semibold mx-2 justify-center'><img src={logo} className='w-12' /> &nbsp;<span className='mx-2 mt-3'>Entropi Directory Services</span></div>
                    <div> <i onClick={closePopup} className="cursor-pointer fa fa-times" aria-hidden="true"></i></div>
                </div>

            </Modal.Header>
            <Modal.Body>
                {/* <Row style={{ width: "100%" }} className='mx-1'>
                    <Col xs={12} md={6}> */}
                <div className="flex flex-col md:flex-row gap-4">
                    <div className="border p-3 flex-1 rounded-xl">
                        <div className='text-sm my-1 py-2 px-2'>
                            <div className='h-auto flex items-center justify-center my-1'>
                                <div><img src={lineone} className='sm:w-52 md:w-32 w-44 px-2' /></div>
                                <div> Entropi Directory Services is a functionality designed for customers utilising the Lead and Deal Management
                                    product on the Entropi Customer Business Platform.</div>
                            </div>
                            <div className='h-auto flex items-center justify-center my-2'>
                                <div><img src={linetwo} className='sm:w-52 md:w-52 w-60 px-2' /></div>
                                <div>Within this service, businesses can showcase their company along with pertinent details about their goods and services.
                                    These listings form part of a search service, enabling users like you to identify suitable companies that meet their specific requirements.
                                </div>
                            </div>
                            <div className='h-auto flex items-center justify-center my-2'>
                                <div> <img src={linethree} className='sm:w-52 md:w-32 w-44 px-2' /></div>
                                <div>Please note that your details provided here will be disclosed to the respective company for
                                    the purpose of facilitating professional business discussions.</div>
                            </div>
                            <div className='h-auto flex items-center justify-center my-2'>
                                <div><img src={linefour} className='sm:w-52 md:w-32 w-44 px-2' /></div>
                                <div>It is important to note that Entropi will not disclose your details to any third parties and assumes no responsibility
                                    for your business needs or conversations.</div>
                            </div>
                        </div>
                    </div>
                    {/* </Col>
                    <Col xs={12} md={6}> */}
                    <div className="border p-4 flex-1 rounded-xl sm:mt-4 md:mt-0">
                        <div className='my-1 py-4 px-2'>
                            <input className={`text-sm w-full px-2 py-2 ${errors.nameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                type="text"
                                onChange={(e) => { setName(e.target.value); setErrors({ ...errors, nameError: false }) }}
                                name="name"
                                value={name}
                                placeholder="Name" />
                            <p className={`h-4 ${errors.nameError ? "text-[10px] text-red-500" : "invisible"}`}>Please enter name</p>
                            <input className={`text-sm w-full px-2 py-2 ${errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                type="email"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value); setErrors({ ...errors, emailError: false }) }}
                                name="email"
                                placeholder="Email" />
                            <div className={`h-4 ${errors.emailError ? "text-[10px] text-red-500" : "invisible"}`}>Invalid Email</div>
                            <input className={`text-sm w-full px-2 py-2 ${errors.mobileError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                type="text"
                                value={mobile}
                                onChange={(e) => { setMobile(e.target.value); setErrors({ ...errors, mobileError: false }) }}
                                name="mobile"
                                placeholder="Mobile" />
                            <div className={`h-4 ${errors.mobileError ? "text-[10px] text-red-500" : "invisible"}`}>Invalid mobile number</div>
                            <input className={`text-sm w-full px-2 py-2 border-[1px] border-gray-300 rounded`}
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                name="city"
                                placeholder="City" />

                            <div className='mt-1 flex items-center justify-center text-sm'>
                                <div> <img src={linethree} className='sm:w-52 md:w-32 w-44 px-2' /></div>
                                <div> Please note that your details provided above will be disclosed to the respective company for
                                    the purpose of facilitating professional business discussions.</div>
                            </div>
                            <div className='flex justify-end mb-2 w-full'>
                                <button className='absolute bottom-7 border-[1px] bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs'
                                    onClick={verifyUser}>Submit</button>
                            </div>
                        </div>


                        {/* </Col>

                </Row> */}
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
}
