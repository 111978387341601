import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../images/logo-rambuilders.svg'
import { Col, Row } from 'react-bootstrap';
import TopBar from './TopBar';
import TopBar1 from './TopBar1';

const OtpVerified = () => {
    const navigate = useNavigate();

    useEffect(() => {
    }, [])
    const handleNavigate = () => {
        navigate('/')
    }
    return (
        <section className="flex flex-col">
            <TopBar1 />
            <Row style={{ width: "100%" }}>
                <Col md={3}></Col>
                <Col md={6} className='mt-[25vh] border-2 rounded-xl'>
                    <div className="text-center md:text-left">
                        <label className="mt-4 mr-1 text-xl font-semibold">OTP Verified Successfully !!</label>
                    </div>
                    <div className='mt-6 flex text-left px-3'>
                        <div className='text-base'>Otp verified successfully.
                            Please complete the login process to start using this tool</div>
                    </div>
                    <div className="mb-3 flex justify-end text-center md:text-left">
                        <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" type="submit" onClick={handleNavigate}>Back</button>
                    </div>

                </Col>
            </Row>
        </section>
    )
}

export default OtpVerified;