import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Search.css'
import ReactSelect from './ReactSelect';
import verified from '../../images/verified.svg';
import threadality from '../../images/threadality-logo.svg';
import axios from 'axios';
import { config } from '../../Config/Config';
import ResultsScreen from './ResultsScreen';
import { enqueueSnackbar } from 'notistack';
import { Spinner } from 'react-bootstrap';
import info from '../../images/info.svg';
import TopBar1 from './TopBar1';

export default function SearchScreen() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = location?.state || { user: null };
    const [companyDetails, setCompanyDetails] = useState([])
    const [selectedIndustry, setSelectedIndustry] = useState("")
    const [selectedSector, setSelectedSector] = useState("")
    const [selectedTag, setSelectedTag] = useState([]);
    const [industries, setIndustries] = useState([])
    const [sectors, setSectors] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [detailsData, setDetailsData] = useState();
    const [showDetailsPopup, setShowDetailsPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchResult, setSearchResult] = useState(false)

    useEffect(() => {
        if (!user) navigate("/", { replace: true })
        getSectors();
        getAllUniqueTags();
        console.log("user", user)
    }, []);
    const getSectors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/allAvailableIndustryTypes', { headers: headers })
            .then(function (response) {
                console.log("success available industryTypes", response.data);
                setIndustries(response.data.industryTypes);
                setSectors(response.data.sectorTypes);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getAllUniqueTags = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'manager/getUniqueTags', { headers: headers })
            .then(function (response) {
                console.log("success available tags", response.data);
                let cur_tags = response.data.uniqueTags;
                let tag_options = cur_tags && cur_tags.map((tag, i) => { return { value: i, label: tag } })
                setTags(tag_options);
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const handleSearchBtn = () => {
        if (!selectedIndustry) {
            enqueueSnackbar("Please select the industry type", { variant: "error" })
        }
        setLoading(true)
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web",
            "Accept": 'application/json'
        }
        var payload = {
            "tags": selectedTag,
            "sectorType": selectedSector ? parseInt(selectedSector) : null,
            "industryType": selectedIndustry ? parseInt(selectedIndustry) : null,
            "city": user?.city
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/searchByUserOnDirectoryService', payload, { headers: headers })
            .then(function (response) {
                console.log(response.data);
                let comData = response.data.companyData;
                comData.sort((a, b) => {
                    const aVerifiedMatch = a.verified === true && a.city === user.city;
                    const bVerifiedMatch = b.verified === true && b.city === user.city;
                    if (aVerifiedMatch && !bVerifiedMatch) {
                        return -1;
                    } else if (!aVerifiedMatch && bVerifiedMatch) {
                        return 1;
                    }
                    const verifiedComparison = b.verified - a.verified;
                    return verifiedComparison;
                });
                setCompanyDetails(comData);
                setLoading(false);
                setSearchResult(true);
            })
            .catch(function (error) {
                console.log("error", error, payload);
                setLoading(false);
                setCompanyDetails([]);
                setSearchResult(true);
            });
    }

    const handleChange = (option, action) => {
        console.log("option", option, action, selectedTag);
        let curOption = JSON.parse(JSON.stringify(option))
        if (option.length <= 2) {
            setSelectedOptions(curOption);
            setSelectedTag(curOption.map(item => item.label));
        }
    }

    const toggleDetailsPopup = (data = {}) => {
        setDetailsData(data);
        setShowDetailsPopup(!showDetailsPopup);
    }
    return (
        <div>
            {showDetailsPopup && <ResultsScreen data={detailsData} togglePopup={toggleDetailsPopup} user={user} />}
            {/* <div className="sticky top-0 z-50 flex min-w-[100vw] h-12 items-center justify-left" style={{ color: "black", background: "#ebf1ff" }}>
                <div className='justify-left items-center ml-3 w-10 cursor-pointer'><Link to="/"><img src={logo} /></Link></div>
                <div className=" md:text-2xl sm:text-2xl font-semibold w-[100%] text-center items-center">
                </div>
                <div>
                    <div className='ml-2 mr-4 w-28 sm:w-20 cursor-pointer'> <img src={threadality} /></div>
                </div>
            </div> */}
            <TopBar1 />
            <div className='mt-3 mx-4'>
                <div className='flex items-center justify-between font-semibold'>
                    <p className='text-xl'>Entropi Directory Services</p>
                    <p className='text-lg'>Hello,{user && user.name}</p>
                </div>
                <div className='flex flex-col lg:flex-row  gap-4 items-center justify-around min-h-[10vh] border-2 border-orange-500 rounded-lg m-2 p-2'>
                    <p className='text-lg lg:text-sm font-medium'>Search for Company</p>
                    <select name="selectedIndustry" value={selectedIndustry} className={`text-xs w-[60%] lg:w-[20vw] p-2 border-[1px] border-gray-300 rounded-lg`}
                        onChange={(e) => setSelectedIndustry(e.target.value)}>
                        <option value="">Select Industry</option>
                        {industries && industries.map(item => {
                            return (
                                <option key={item.id} value={item.id}>{item.industryType}</option>
                            )
                        })}
                    </select>
                    <select name="selectedSector" value={selectedSector} className={`text-xs  w-[60%] lg:w-[20vw] p-2 border-[1px] border-gray-300 rounded-lg`}
                        onChange={(e) => { setSelectedSector(e.target.value) }}>
                        <option value="">Select Sector Type</option>
                        {sectors && sectors.map(item => {
                            return (
                                <option key={item.id} value={item.id}>{item.sectorType}</option>
                            )
                        })}
                    </select>
                    <div className='min-w-[60%] lg:min-w-[35vw] max-w-[60%] lg:max-w-[40vw] text-xs'>
                        <ReactSelect options={tags} handleChange={handleChange} placeholder={"--Select Upto Two Tags--"}
                            maxValues={2} value={selectedOptions} />
                    </div>
                    <button className='border-[1px] bg-black hover:bg-black text-white p-2 font-semibold uppercase rounded text-xs'
                        onClick={() => handleSearchBtn()}>Search</button>
                </div>
            </div>
            {loading ?
                <div className='mt-20 flex justify-center items-center'><Spinner animation="border" /></div> :
                companyDetails && companyDetails.length > 0 ?
                    <div className="mt-3 tableFixHead sm:mx-1 md:mx-5 ">
                        <p className='font-semibold text-lg text-center my-2'>Search Result</p>
                        <table className="" >
                            <tbody className=''>
                                {companyDetails && companyDetails.map((data, i) => (
                                    <tr key={data.companyId} className="tr whitespace-nowrap"
                                        style={{ borderRadius: "35px", border: "none", "boxShadow": "2px 2px 4px #a8a8a8, -3px -3px 6px #ffffff" }}>
                                        <td className="td-text w-32">
                                            <div className='flex w-20 justify-between items-center'>
                                                <img src={data.logo_url} alt="" className='w-10 h-10 border border-black rounded-lg' />
                                                {data.verified && <img src={verified} alt="verified" className='w-5 h-5' />}
                                            </div>
                                        </td>
                                        <td className="td-text w-[25%]">
                                            <div className='font-medium text-sm'>
                                                <p>{data.name}</p>
                                                <p>{data && data.city}{data && data.country ? "," : ""}{data && data.country}</p>
                                            </div>
                                        </td>
                                        <td className="td-text text-sm">
                                            <p className='font-medium'>{data.secType}</p>
                                        </td>
                                        <td className="td-text text-sm">
                                            <div className='flex showTags'>
                                                {data.tags && data.tags.map((tag, i) => <span key={i} className='font-medium'>{tag}{i < data.tags.length - 1 ? "," : ""}</span>)}
                                            </div>
                                        </td>
                                        <td className="td-text md:float-right text-center mt-1">
                                            <div className={`ml-2 px-2 icon-w text-right`} onClick={() => toggleDetailsPopup(data)} >
                                                <img src={info} />
                                            </div>
                                            {/* <img src={info} alt='' className='w-8 h-8 cursor-pointer' onClick={() => toggleDetailsPopup(data)} /> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    : searchResult ?
                        < div className='mt-10 flex justify-center items-center font-semibold text-xl'> No Data Found</div>
                        : <></>
            }
        </div >
    )
}
