export const config = {
    "apiUrl": "https://leadmngt-backend-rl9wb.ondigitalocean.app/",
    "PASSWORD_ENCRYPTION_KEY": "zxcvbnmasdfghjkl",
    "uploadUrl": "https://api.cloudinary.com/v1_1/entropi/image/upload",
    "frontendUrl": "https://www.entropi.work/",
    "feddupUserUrl": "https://feddup-user-tcwuu.ondigitalocean.app/",
    "feddupLink": "https://www.feddup.me/by/",
    "email": "elie@entropi.work",
    "branchId": "749efb40-73f2-11ee-a6c7-efb9b0e948ac",
    "publicKey": 'BDRFlkj_kGDLt6lUhtyhGe8wFYz9xDgV1OGmBQ_tGIRyy9sPg_gNC0DKojrJFiTQW3sRBJ0HIz94cVGPENCsSQo'
}
// export const config = {
//     "apiUrl": "http://localhost:8082/",
//     "PASSWORD_ENCRYPTION_KEY": "zxcvbnmasdfghjkl",
//     "uploadUrl": 'https://api.cloudinary.com/v1_1/entropi/image/upload',
//     "frontendUrl": "https://www.entropi.work/",
//     "feddupUserUrl": "https://feddup-user-tcwuu.ondigitalocean.app/",
//     "feddupLink": "https://www.feddup.me/by/",
//     "email": "elie@entropi.work",
//     "branchId": "749efb40-73f2-11ee-a6c7-efb9b0e948ac",
//     "publicKey": 'BDRFlkj_kGDLt6lUhtyhGe8wFYz9xDgV1OGmBQ_tGIRyy9sPg_gNC0DKojrJFiTQW3sRBJ0HIz94cVGPENCsSQo'
// }