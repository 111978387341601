import { clsx } from 'clsx';
import { useState } from 'react';
import Select from 'react-select';

const controlStyles = {
    base: "bg-white hover:cursor-pointer border border-2",
    focus: "",
    nonFocus: "border-gray-300 hover:border-gray-400",
};
const placeholderStyles = "text-gray-500 pl-1 py-0.5 ";
const selectInputStyles = "pl-1 py-0.5";
const valueContainerStyles = "p-1 gap-1";
const singleValueStyles = "leading-7 ml-1";
const multiValuesStyles = "px-2";
const multiValueRemove = "pl-2";
const indicatorsContainerStyles = "p-1 gap-1";
const clearIndicatorStyles =
    "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
const optionStyles = {
    base: "hover:cursor-pointer px-3 py-2 rounded hover:bg-red-400",
    focus: "bg-gray-100 active:bg-red-400",
    selected: "after:content-['✔'] after:ml-2 after:text-green-500 text-gray-500",
};
const noOptionsMessageStyles =
    "text-gray-500 p-2 bg-gray-50 ";

const ReactSelect = (props) => {
    const [value, setValue] = useState("Select...")
    return (
        <Select
            isSearchable={true}
            defaultValue={props.defaultValue}
            value={props.value}
            className="w-full"
            placeholder={props.placeholder}
            options={props.options}
            hideSelectedOptions={true}
            unstyled
            isMulti
            isDisabled={props.isDisabled}
            onChange={(option, action) => {
                props.handleChange(option, action)
            }}
            styles={{
                input: (base) => ({
                    ...base,
                    "input:focus": {
                        boxShadow: "none",
                    },
                }),
                control: (base) => ({
                    ...base,
                    transition: "none",
                }),
            }}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            classNames={{
                control: ({ isFocused }) =>
                    clsx(
                        isFocused ? controlStyles.focus : controlStyles.nonFocus,
                        controlStyles.base,
                    ),
                placeholder: () => placeholderStyles,
                input: () => selectInputStyles,
                valueContainer: () => valueContainerStyles,
                singleValue: () => singleValueStyles,
                multiValue: () => multiValuesStyles,
                multiValueRemove: () => multiValueRemove,
                indicatorsContainer: () => indicatorsContainerStyles,
                clearIndicator: () => clearIndicatorStyles,
                menu: () => menuStyles,
                groupHeading: () => groupHeadingStyles,
                option: ({ isFocused, isSelected }) =>
                    clsx(
                        isFocused && optionStyles.focus,
                        isSelected && optionStyles.selected,
                        optionStyles.base,
                    ),
                noOptionsMessage: () => noOptionsMessageStyles,
            }}
            {...props}
        />
    )
}
export default ReactSelect;