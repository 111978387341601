import React from 'react';
import logo from '../../images/logo-rambuilders.svg';
import threadality from '../../images/threadality-logo.svg';
import marketplace from '../../images/marketplace.svg';
import fillcompanydetails from '../../images/fillcompanydetails.svg';
import beforesearch from '../../images/beforesearchscreen.svg';
import searchresults from '../../images/searchresults.svg';
import companydetails from '../../images/companydetails.svg';
import directoryleads from '../../images/directoryleads.svg';
import registercase from '../../images/registercase.svg';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import TopBar1 from './TopBar1';


export default function DirectoryFlow() {
    return (
        <div>
            {/* <div className="sticky top-0 z-50 flex min-w-[100vw] h-12 items-center justify-left" style={{ color: "black", background: "#ebf1ff" }}>
                <div className='justify-left items-center ml-3 w-10 cursor-pointer'><Link to="/"><img src={logo} /></Link></div>
                <div className=" md:text-2xl sm:text-2xl font-semibold w-[100%] text-center items-center">
                </div>
                <div className='ml-2 mr-4 w-28 sm:w-20 cursor-pointer'> <img src={threadality} /></div>
            </div> */}
            <TopBar1 />
            <div>
                <div className='font-semibold text-center text-3xl py-2'>Entropi Directory Services</div>
                <div className='mx-10'>

                    <p className='font-semibold text-xl mt-2'>Registration</p>
                    <Row style={{ width: "100%" }} className='my-4'>
                        <Col md={6} sm={6} xs={12}>
                            <div className='h-full flex flex-col items-start justify-center'>
                                <p className='mt-2 text-left text-base'>This represents an added functionality offered to Entropi users, accessible through the selectable option labeled "Directory Services"
                                    within the "Marketplace" section.</p>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={12}>
                            <div className='w-full h-full flex justify-center'>  <img src={marketplace} className='directory-img' /></div>
                        </Col>
                    </Row><hr />
                    <Row style={{ width: "100%" }} className='my-4'>
                        <Col md={{ order: 'first', span: 6 }} xs={{ order: 'last', span: 12 }} sm={{ order: 'first', span: 6 }}>
                            <div className='w-full h-full flex justify-center'>  <img src={fillcompanydetails} className='directory-img' /></div>
                        </Col>
                        <Col md={{ order: 'last', span: 6 }} xs={{ order: 'first', span: 12 }} sm={{ order: 'last', span: 6 }}>
                            <div className='h-full flex flex-col items-start justify-center'>
                                <p className='mt-2 text-left text-base'> Provide Company details and required information for search results.</p>
                            </div>
                        </Col>
                    </Row><hr />

                    <p className='font-semibold text-xl mt-4'>Directory Search</p>
                    <Row style={{ width: "100%" }} className='my-4'>
                        <Col md={6} sm={6} xs={12}>
                            <div className='h-full flex flex-col items-start justify-center'>
                                <p className='mt-2 text-left text-base'>Information of the individual conducting search is solicited.</p>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={12}>
                            <div className='w-full h-full flex justify-center'>  <img src={beforesearch} className='directory-img' /></div>
                        </Col>
                    </Row><hr />
                    <Row style={{ width: "100%" }} className='my-4'>
                        <Col md={{ order: 'first', span: 6 }} xs={{ order: 'last', span: 12 }} sm={{ order: 'first', span: 6 }}>
                            <div className='w-full h-full flex justify-center'>  <img src={searchresults} className='directory-img' /></div>
                        </Col>
                        <Col md={{ order: 'last', span: 6 }} xs={{ order: 'first', span: 12 }} sm={{ order: 'last', span: 6 }}>
                            <div className='h-full flex flex-col items-start justify-center'>
                                <p className='mt-2 text-left text-base'>Based on search criteria the results are shown.</p>
                            </div>
                        </Col>
                    </Row><hr />
                    <Row style={{ width: "100%" }} className='my-4'>
                        <Col md={6} sm={6} xs={12}>
                            <div className='h-full flex flex-col items-start justify-center'>
                                <p className='mt-2 text-left text-base'>Click on a company on which you would like to have more information</p>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={12}>
                            <div className='w-full h-full flex justify-center'>  <img src={companydetails} className='directory-img' /></div>
                        </Col>
                    </Row><hr />
                    <p className='font-semibold text-xl mt-4'>Other Information</p>
                    <Row style={{ width: "100%" }} className='my-4'>
                        <Col md={{ order: 'first', span: 6 }} xs={{ order: 'last', span: 12 }} sm={{ order: 'first', span: 6 }}>
                            <div className='w-full h-full flex justify-center'>  <img src={directoryleads} className='directory-img' /></div>
                        </Col>
                        <Col md={{ order: 'last', span: 6 }} xs={{ order: 'first', span: 12 }} sm={{ order: 'last', span: 6 }}>
                            <div className='h-full flex flex-col items-start justify-center'>
                                <p className='mt-2 text-left text-base'>Details of the person who searched for a company will be shared to that company.
                                </p>
                            </div>
                        </Col>
                    </Row><hr />
                    <Row style={{ width: "100%" }} className='my-4'>
                        <Col md={6} sm={6} xs={12}>
                            <div className='h-full flex flex-col items-start justify-center'>
                                <p className='mt-2 text-left text-base'>Company can Opt Out of this service by raising a case.</p>
                            </div>
                        </Col>
                        <Col md={6} sm={6} xs={12}>
                            <div className='w-full h-full flex justify-center'>  <img src={registercase} className='directory-img w-[50%]' /></div>
                        </Col>
                    </Row>
                    <hr />
                </div>
            </div>
        </div>
    )
}
