import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import landingpageimg from '../images/landingpage.svg';
import { Row, Col, Container, Button } from 'react-bootstrap';
import logo from '../images/logo-rambuilders.svg';
import settings from '../images/settings.svg';
import SimpleCrypto from "simple-crypto-js";
import axios from 'axios';
import { config } from '../Config/Config'
import TopBar from '../components/Register/TopBar';
import Footer from '../Footer/Footer';
import Popup from '../components/Register/Popup';
import milestone from '../images/milestone feddup.svg';
import realestate from '../images/realestate.svg';
import traininginstitute from '../images/traininginstitute.svg';
import personalcoach from '../images/personalcoach.svg';
import financialadvisor from '../images/financialadvisor.svg';
import salesagent from '../images/salesagent.svg';
import ngo from '../images/ngo.svg';
import general from '../images/general.svg';
import simplesetup from '../images/simplesetup.svg';
import bulkupload from '../images/bulkupload.svg';
import rewards from '../images/rewards.svg';
import targets from '../images/targets.svg';
import analytics from '../images/analytics.svg';
import events from '../images/events.svg';
import jewellery from '../images/jewellery.svg';
import deal from '../images/deal1.svg';
import healthcare from '../images/healthcare.svg';
import foodtech from '../images/foodtech.svg';
import hospitality from '../images/hospitality.svg';
import textile from '../images/textile.svg';
import it from '../images/it.svg';
import sharedcalender from '../images/sharedcalendar.svg';
import marketplace from '../images/marketplace-lp.svg';
import AOS from "aos";
import "aos/dist/aos.css";


var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)
const LandingPage = () => {
    const { companyname, id: uid } = useParams();
    const [companyDetails, setCompanyDetails] = useState({});
    const [companies, setCompanies] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [id, setId] = useState('');
    const [rating, setRating] = useState('');
    const [ratings, setRatings] = useState([]);
    const [avgRating, setAvgRating] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [averageRatingCount, setAverageRatingCount] = useState('');
    const [url, setUrl] = useState('');
    const [randomLogos, setRandomLogos] = useState([]);
    const [noOfRaters, setNoOfRaters] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {

        getAllCompanyLogos()
        getProductDetails()

        // if (companyname && uid) {
        //     getCompanyDetails()
        // }
    }, [])
    useEffect(() => {
        AOS.init({
            duration: 1500
        });
    }, []);
    const getProductDetails = () => {
        let data = document.getElementById('#feddup12345')?.innerHTML;
        if (data) {
            let dataId = data.split("\"");
            if (dataId[dataId.length - 1].length > 12) {
                dataId = dataId[dataId.length - 1];
                setId(dataId)
            }
            else if (dataId[dataId.length - 2].length > 12) {
                dataId = dataId[dataId.length - 2];
                setId(dataId)
            }
            else {
                setId(dataId)
            }
            var payload = {
                "productId": dataId
            }
        }
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        console.log("payload", payload)
        axios.post(config.feddupUserUrl + "user/getTopThreeRatingByProductId", payload, { headers: headers })
            .then(function (response) {
                console.log("getTopThreeRatingByProductId", response);
                if (response.data.status === true) {
                    setRatings(response.data.numberOfRating)
                    setAverageRatingCount(response.data.averageRating[0].avgrating)
                    setNoOfRaters(response.data.numberOfRaters)
                    setUrl(response.data.url)
                    setIsLoading(false)
                    setAvgRating(`${((Math.round(response.data.averageRating[0].avgrating * 10) / 10) / 5) * 100}%`)
                    setRating("100" - `${((Math.round(response.data.averageRating[0].avgrating * 10) / 10) / 5) * 100}`)
                    console.log("res", response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    // const getCompanyDetails = async () => {
    //     let payload = {
    //         companyName: companyname,
    //         uniqueCode: uid
    //     }
    //     axios.post(config.apiUrl + 'employees/getCompanyDetailsByUniqueCode', payload)
    //         .then(function (response) {
    //             if (response.data.status === true) {
    //                 console.log("company details", response.data);
    //                 if (response.data?.details?.length > 0) {
    //                     setCompanyDetails(response.data.details[0]);
    //                     localStorage.setItem("cName", simpleCrypto.encrypt(response.data.details[0]));
    //                 }
    //             } else {
    //                 console.log(response.data.message)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error", error);
    //         });
    // }
    const getAllCompanyLogos = async () => {
        axios.get(config.apiUrl + 'companyAdmin/getAllCompanyLogos')
            .then(function (response) {
                if (response.data.status === true) {
                    setCompanies(response.data.companies);
                    const shuffledArray = [...response.data.companies];
                    for (let i = shuffledArray.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
                    }

                    setRandomLogos(shuffledArray);
                } else {
                    console.log(response.data.message)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    }
    const handleNavigate = () => {
        navigate('/register')
    }
    const handleNavigateToNew = () => {
        navigate('/directoryservices')
    }
    const getRandomCompanyLogo = () => {
        const randomCompany = Math.floor(Math.random() * companies.length);
        return companies[randomCompany].logo || logo

    };
    const togglePopup = () => {
        setShowPopup(!showPopup)
    }
    return (
        <>
            <TopBar />
            <div>
                <div className='lg:flex-row flex mt-[3vh] flex-col justify-center items-center min-h-[90vh] mx-4'>
                    <div>
                        <img src={landingpageimg} className="w-[95%]" />
                    </div>
                    <div className="loading-animation flex flex-col justify-between -mt-4 text-right font-bold lp-text-size whitespace-nowrap md:h-[55vh]">
                        <div>
                            <p>Streamline your leads,</p>
                            <p>seal the deals</p>
                            <p>effortlessly!</p>
                        </div>
                        <div className='flex flex-col h-full items-center justify-center text-base bg_image'>
                            <div onClick={handleNavigateToNew} className='cursor-pointer'>
                                <p className='sm:-mt-[10vh] md:mt-[1vh] mt-[-12vh]'>Join Entropi Directory </p>
                                <p>Services to connect with </p>
                                <p>potential customers</p>
                            </div>
                        </div>
                    </div>
                </div><br />

                <Row style={{ width: "100%" }} className='no-gutters'>
                    <Col md={5} xs={12} className='bg-green-50 rounded-tr-full rounded-br-full'>
                        <div className='h-full flex flex-col items-start justify-center'>
                            <center className='my-2 lg:text-3xl md:text-2xl text-2xl font-semibold text-left text-align-left'>Designed for Small, Medium, Solopreneurs</center>
                            <center className='my-3 text-base text-left text-align-left'>Entropi is designed to access, contact, and convert leads generated from online, brick-and-mortar and social media by using their phone or laptop. <br /><p className='my-3'>Entropi influences following sectors</p></center>
                        </div>
                    </Col>
                    <Col md={7} xs={12} className='my-10'>
                        <div className="flip-lr bg-amber-50 rounded-tl-3xl rounded-br-3xl shadow"><br />
                            <center><p className='-mt-2 text-xs'>Hover on circles to know more</p></center>
                            <Row style={{ width: "100%" }} className="no-gutters ">
                                <Col md={3} xs={12} sm={12} className='mt-4'>
                                    <center className="team-role">
                                        <div className="flip-box">
                                            <div className="flip-box-inner">
                                                <div className="flip-box-front">
                                                    <center>
                                                        <img src={realestate} className="offer-each-icon" />
                                                    </center>
                                                </div>
                                                <div className="flip-box-back">
                                                    <p className=" px-1">Builders, Architects, Suppliers, Plumbing, Marketing..</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-1 offer-text">CONSTRUCTION</div>
                                    </center>
                                </Col>
                                <Col md={3} xs={12} sm={12} className='mt-4'>
                                    <center className="team-role">
                                        <div className="flip-box">
                                            <div className="flip-box-inner">
                                                <div className="flip-box-front">
                                                    <center>
                                                        <img src={it} className="offer-each-icon" />
                                                    </center>
                                                </div>
                                                <div className="flip-box-back">
                                                    <p className=" px-1">UI/UX, Digital Marketing, Build, Test, Hosting, IoT..</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-1 offer-text">INFORMATION TECHNOLOGY</div>
                                    </center>

                                </Col>
                                <Col md={3} xs={12} sm={12} className='mt-4'>
                                    <center className="team-role">
                                        <div className="flip-box">
                                            <div className="flip-box-inner">
                                                <div className="flip-box-front">
                                                    <img src={traininginstitute} className="offer-each-icon" />
                                                </div>
                                                <div className="flip-box-back">
                                                    <p className="px-1">Technical, Domain, Professional, Yoga, Music, Dance..</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-1 offer-text">EDUCATION & EDUTECH</div>
                                    </center>
                                </Col>
                                <Col md={3} xs={12} sm={12} className='mt-4'>
                                    <center className="team-role">
                                        <div className="flip-box">
                                            <div className="flip-box-inner">
                                                <div className="flip-box-front">
                                                    <img src={financialadvisor} className="offer-each-icon" />
                                                </div>
                                                <div className="flip-box-back">
                                                    <p className="px-1">Retail Lending, Investment, Insurance, Business Loan..</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-1 offer-text">FINANCIAL SERVICES & FINTECH</div>
                                    </center>
                                </Col>
                            </Row>
                            <Row style={{ width: "100%" }} className="no-gutters">
                                <Col md={3} xs={12} sm={12} className='mt-4'>
                                    <center className="team-role">
                                        <div className="flip-box">
                                            <div className="flip-box-inner">
                                                <div className="flip-box-front">
                                                    <img src={hospitality} className="offer-each-icon" />
                                                </div>
                                                <div className="flip-box-back">
                                                    <p className="px-1">Agents, Accommodation, Chefs, Medical, Consulting..</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-1 offer-text">TOURISM & HOSPITALITY</div>
                                    </center>
                                </Col>
                                <Col md={3} xs={12} sm={12} className='mt-4'>
                                    <center className="team-role">
                                        <div className="flip-box">
                                            <div className="flip-box-inner">
                                                <div className="flip-box-front">
                                                    <center>
                                                        <img src={textile} className="offer-each-icon" />
                                                    </center>
                                                </div>
                                                <div className="flip-box-back">
                                                    <p className="px-1">Jewellery, Niche Products, Consulting, Styling..</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-1 offer-text">TEXTILE & FASHION</div>
                                    </center>

                                </Col>
                                <Col md={3} xs={12} sm={12} className='mt-4'>
                                    <center className="team-role">
                                        <div className="flip-box">
                                            <div className="flip-box-inner">
                                                <div className="flip-box-front">
                                                    <img src={foodtech} className="offer-each-icon" />
                                                </div>
                                                <div className="flip-box-back">
                                                    <p className="px-1">Receipe, Processing, Logistics, Consulting, Machinery..</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-1 offer-text">FOODTECH</div>
                                    </center>
                                </Col>
                                <Col md={3} xs={12} sm={12} className='mt-4'>
                                    <center className="team-role">
                                        <div className="flip-box">
                                            <div className="flip-box-inner">
                                                <div className="flip-box-front">
                                                    <img src={healthcare} className="offer-each-icon" />
                                                </div>
                                                <div className="flip-box-back">
                                                    <p className="px-1">Labs, Suppliers, Pharma, Ayurveda, Homeopathy..</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-1 offer-text">HEALTHCARE & PHARMA</div>
                                    </center>
                                </Col>
                            </Row>

                            <center className='mt-4 text-3xl font-semibold uppercase'>AND MORE...</center>
                            <center><div className='mt-1 text-base'>Our aim is to cover virtually every domain.</div></center>
                            <br />
                        </div>

                    </Col>
                </Row>
                <br /><br />
                <center className='my-12 text-3xl font-semibold'>Salient Features</center>
                <center>
                    <div className='features-box-1 bg-lime-50 mt-5 flex  justify-center' data-aos="fade-up">
                        <Row style={{ width: "100%" }}>
                            <Col md={6} sm={6} xs={12}>
                                <div className='h-full flex flex-col items-start justify-center'>
                                    <p className='text-left text-2xl font-semibold'>Simple setup</p>
                                    <p className='mt-2 text-left text-base'> Setup your business with branches, products, relationship managers with ease.</p>
                                </div>
                            </Col>
                            <Col md={6} sm={6} xs={12}>
                                <div className='w-full h-full flex justify-center'>  <img src={simplesetup} className='image simple-img' /></div>
                            </Col>
                        </Row>
                    </div>
                    <div className='features-box-1 bg-orange-50 mt-5 flex justify-center' data-aos="fade-up">
                        <Row style={{ width: "100%" }}>
                            <Col md={{ order: 'first', span: 6 }} xs={{ order: 'last', span: 12 }} sm={{ order: 'first', span: 6 }}>
                                <div className='w-full h-full flex justify-center'>  <img src={bulkupload} className='image simple-img' /></div>
                            </Col>
                            <Col md={{ order: 'last', span: 6 }} xs={{ order: 'first', span: 12 }} sm={{ order: 'last', span: 6 }}>
                                <div className='h-full flex flex-col items-start justify-center'>
                                    <p className='text-left text-2xl font-semibold'>Faster Client onboarding</p>
                                    <p className='mt-2 text-left text-base'>Upload your client data in bulk, create exclusively using web app or mobile app.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='features-box-1 bg-sky-50 mt-5 flex justify-center' data-aos="fade-up">
                        <Row style={{ width: "100%" }}>
                            <Col md={6} sm={6} xs={12}>
                                <div className='h-full flex flex-col items-start justify-center'>
                                    <p className='text-left text-2xl font-semibold'>Robust Client Journey</p>
                                    <p className='mt-2 text-left text-base'>Manage client journey of impulse or sequential deal with commited approach to complete transaction with customer satisfaction.</p>
                                </div>
                            </Col>
                            <Col md={6} sm={6} xs={12}>
                                <div className='w-full h-full flex justify-center'>  <img src={deal} className='image simple-img' /></div>
                            </Col>
                        </Row>
                    </div>
                    {/* <div className='features-box-1 bg-fuchsia-50 mt-5 flex justify-center'>
                        <Row style={{ width: "100%" }}>
                            <Col md={{ order: 'first', span: 6 }} xs={{ order: 'last', span: 12 }} sm={{ order: 'first', span: 6 }}>
                                <div className='w-full h-full flex justify-center'>  <img src={sharedcalender} className='image simple-img' /></div>
                            </Col>
                            <Col md={{ order: 'last', span: 6 }} xs={{ order: 'first', span: 12 }} sm={{ order: 'last', span: 6 }}>
                                <div className='h-full flex flex-col items-start justify-center'>
                                    <p className='text-left text-2xl font-semibold'>Manage time efficiently</p>
                                    <p className='mt-2 text-left text-base'>Plan customer interactions by self or share calendar with customers for organised connects.</p>
                                    <p className='mt-2 text-left text-base'><i>This is part of marketplace.</i></p>
                                </div>
                            </Col>
                        </Row>
                    </div> */}
                    <div className='features-box-1 bg-slate-50 mt-5 flex justify-center' data-aos="fade-up">
                        <Row style={{ width: "100%" }}>
                            <Col md={{ order: 'first', span: 6 }} xs={{ order: 'last', span: 12 }} sm={{ order: 'first', span: 6 }}>
                                <div className='w-full h-full flex justify-center'>  <img src={analytics} className='image simple-img' /></div>
                            </Col>
                            <Col md={{ order: 'last', span: 6 }} xs={{ order: 'first', span: 12 }} sm={{ order: 'last', span: 6 }}>
                                <div className='h-full flex flex-col items-start justify-center'>
                                    <p className='text-left text-2xl font-semibold'>Detailed Analytics</p>
                                    <p className='mt-2 text-left text-base'>Company, Branch, Employee, Product and Customer analytics to understand customer needs and product reception.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='features-box-1 bg-yellow-50 mt-5 flex justify-center' data-aos="fade-up">
                        <Row style={{ width: "100%" }}>
                            <Col md={6} sm={6} xs={12}>
                                <div className='h-full flex flex-col items-start justify-center'>
                                    <p className='text-left text-2xl font-semibold'>Reward team</p>
                                    <p className='mt-2 text-left text-base'>Ensure relationship managers are rewarded regularly to keep high motivation levels.</p>
                                </div>
                            </Col>
                            <Col md={6} sm={6} xs={12}>
                                <div className='w-full h-full flex justify-center'>  <img src={rewards} className='image simple-img' /></div>
                            </Col>
                        </Row>
                    </div>
                    <div className='features-box-1 bg-green-50 mt-5 flex justify-center' data-aos="fade-up">
                        <Row style={{ width: "100%" }}>
                            <Col md={{ order: 'first', span: 6 }} xs={{ order: 'last', span: 12 }} sm={{ order: 'first', span: 6 }}>
                                <div className='h-full w-full flex justify-center'>  <img src={targets} className='image simple-img' /></div>
                            </Col>
                            <Col md={{ order: 'last', span: 6 }} xs={{ order: 'first', span: 12 }} sm={{ order: 'last', span: 6 }}>
                                <div className='h-full flex flex-col items-start justify-center'>
                                    <p className='text-left text-2xl font-semibold'>Set targets and track regularly</p>
                                    <p className='mt-2 text-left text-base'>Set yearly targets across organisation, branches and people. Forecast on monthly basis and track with actuals.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='features-box-1 bg-pink-50 mt-5 flex justify-center' data-aos="fade-up">
                        <Row style={{ width: "100%" }}>
                            <Col md={6} sm={6} xs={12}>
                                <div className='h-full flex flex-col items-start justify-center'>
                                    <p className='text-left text-2xl font-semibold'>Events and campaign</p>
                                    <p className='mt-2 text-left text-base'> Define events across business or specific to a branch. Prompt sales teams with same for better customer experience.</p>
                                </div>
                            </Col>
                            <Col md={6} sm={6} xs={12}>
                                <div className='w-full flex justify-center h-full'>  <img src={events} className='image simple-img' /></div>
                            </Col>
                        </Row>
                    </div>
                    <div className='features-box-1 bg-purple-100 mt-5 flex justify-center' data-aos="fade-up">
                        <Row style={{ width: "100%" }}>
                            <Col md={{ order: 'first', span: 6 }} xs={{ order: 'last', span: 12 }} sm={{ order: 'first', span: 6 }}>
                                <div className='h-full w-full flex justify-center'><img src={marketplace} className='image simple-img' /> </div>

                            </Col>
                            <Col md={{ order: 'last', span: 6 }} xs={{ order: 'first', span: 12 }} sm={{ order: 'last', span: 6 }}>
                                <div className='h-full flex flex-col items-start justify-center'>
                                    <p className='text-left text-2xl font-semibold'>Marketplace for you</p>
                                    <p className='mt-2 text-left text-base'>Benefit from additional services provided from Entropi platform as it suites your business needs.
                                        <p className='mt-4 text-left text-base'>"Example, Register your business with “Entropi Directory Services” to get visibility of your business offerings by other users."</p>
                                    </p>
                                </div>
                            </Col>

                        </Row>
                    </div>
                    <div className='features-box-1 bg-fuchsia-50 mt-5 flex justify-center' data-aos="fade-up">
                        <Row style={{ width: "100%" }}>
                            <Col md={6} sm={6} xs={12}>
                                <div className='h-full flex flex-col items-start justify-center'>
                                    <p className='text-left text-2xl font-semibold'>Manage time efficiently*</p>
                                    <p className='mt-2 text-left text-base'>Plan customer interactions by self or share calendar with customers for organised connects.</p>
                                    <p className='mt-5 text-left text-base'><i>*Part of Marketplace offering.</i></p>
                                </div>
                            </Col>
                            <Col md={6} sm={6} xs={12}>
                                <div className='w-full h-full flex justify-center'>  <img src={sharedcalender} className='image simple-img' /></div>
                            </Col>
                        </Row>
                    </div>
                </center >

                <center className='my-20 text-2xl font-semibold'>Our Clients</center>
                <center className='my-16 mx-auto'>
                    <Row style={{ width: "100%" }} >
                        {randomLogos.slice(0, 4).map((data, i) =>
                            <Col key={i}>
                                <div className='clients-cir'>
                                    <div className='clients-inner-cir'>
                                        <img src={data.logo} className='company-logos' />
                                    </div>
                                </div>
                            </Col>
                        )}
                        {companies.length <= 4 ?
                            <Col style={{ display: "none" }}>
                                <div className='clients-cir-text'>+ {companies.length - 4} more</div>
                            </Col>
                            : <Col>
                                <div className='clients-cir-text'>+ {companies.length - 4} more</div>
                            </Col>
                        }
                    </Row>
                </center><br />
                <Row style={{ width: "100%", padding: "20px" }}>
                    <Col md={4}>
                    </Col>
                    <Col md={4}>
                        <div className="feddback_card">
                            <div className="feed-ratings">
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col md={3} xs={3}>
                                        <div className="rating-v-align">
                                            <div className="avgRatingCount">{averageRatingCount}</div>
                                            <img src={milestone} className="milestone-img" /></div>

                                    </Col>
                                    <Col md={9} xs={9}>
                                        <div className="listen-T ml-3 md:whitespace-nowrap latest-ratings">We Listen to your feedback</div>
                                        <div className="ml-3 latest-ratings">Received insightful</div>
                                        <div className="ml-3 md:whitespace-nowrap latest-ratings">feedback from {noOfRaters} users</div>
                                        <a href={config.feddupLink + url} target="_blank" style={{ textDecoration: "none" }}>
                                            <Button variant="default" className="ml-3 btn-block visit-btn">Visit our Feddup page</Button>
                                        </a>
                                    </Col>
                                </Row>

                            </div>
                        </div>


                    </Col>
                    <Col md={4}>
                    </Col>
                </Row>

            </div>
            <Footer />
        </ >
    )
}

export default LandingPage









