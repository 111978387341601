import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from 'axios';
import Chart from 'chart.js/auto';
import { Bar, Pie } from 'react-chartjs-2';
import { config } from "../../Config/Config";


const MeetingCountGraph = ({ empDetails }) => {
    const [values, setValues] = useState();
    const [meetingTypes, setMeetingTypes] = useState();

    useEffect(() => {
        if (empDetails)
            getMeetingCounts()
    }, [empDetails]);
    const getMeetingCounts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        let payload = {
            "empId": empDetails.id
        }
        axios.post(config.apiUrl + 'manager/getMeetingsCountForThisWeek', payload, { headers: headers })
            .then(function (response) {
                if (response.data.status == true) {
                    console.log("meetings count data fetched successfully", response.data);
                    setValues(response.data.meetingsCountData);
                    setMeetingTypes(response.data.meetingTypes);
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const monthlyData = {
        labels: values && values.map(val => val.day),
        datasets: [
            {
                label: "Meeting Count for this Week",
                data: values && values.map(val => val.curCount),
                backgroundColor: ['black'],
                borderWidth: 1,
                barThickness: 10,
                borderThickness: 20
            }
        ]
    };
    const chartData = {
        labels: values && values.map((data) => data.day),
        datasets: [
            {
                data: values && values.map((data) => data.curCount),
                backgroundColor: [
                    'red', 'blue', 'green', 'orange', 'purple', 'pink', 'cyan',
                ],
            },
        ],
    };
    const chartData2 = {
        labels: values && values.map((data) => data.day),
        datasets: [
            {
                label: 'Official',
                data: values && values.map((data) => data.type1),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderWidth: 0,
                borderRadius: 10,
                barPercentage: 0.3,
                categoryPercentage: 1
            },
            {
                label: 'Personal',
                data: values && values.map((data) => data.type2),
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderWidth: 0,
                borderRadius: 10,
                barPercentage: 0.3,
                categoryPercentage: 1
            },
            {
                label: 'Self',
                data: values && values.map((data) => data.type3),
                backgroundColor: 'rgba(255, 160, 132, 0.6)',
                borderWidth: 0,
                borderRadius: 10,
                barPercentage: 0.3,
                categoryPercentage: 1
            },
        ],
    };
    return (
        <>
            {/* <div className="flex gap-2">
                <Card className="flex flex-col gap-2 relative" style={{ marginTop: "1rem", height: "300px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                    <div className="font-semibold">
                        <p className="text-sm text-center">Meeting Count</p>
                    </div>
                    <Pie data={chartData} />
                </Card>
            </div> */}
            <div className="flex gap-2 mt-4">
                <Card className="flex flex-col justify-between gap-2 relative" style={{ marginTop: "1rem", height: "320px", width: "400px", border: "1px solid black", backgroundColor: "white" }}>
                    <div className="font-semibold">
                        <p className="text-sm text-center">Meetings this Week</p>
                    </div>
                    <div className="flex justify-around items-center max-w-[400px] flex-1">
                        <div className="flex justify-center items-center gap-1"><span className="bg-[#4BC0C0] w-2 h-2"></span><span className="text-xs">Official</span></div>
                        <div className="flex justify-center items-center gap-1"><span className="bg-[#FF6384] w-2 h-2"></span><span className="text-xs">Personal</span></div>
                        <div className="flex justify-center items-center gap-1"><span className="bg-[#FFA084] w-2 h-2"></span><span className="text-xs">Self</span></div>
                    </div>
                    <Bar data={chartData2}
                        height={300}
                        options={{
                            maintainAspectRatio: true,
                            responsive: true,
                            redraw: false,
                            elements: {
                                line: {
                                    tension: 1
                                }
                            },
                            scales: {
                                x: {
                                    stacked: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                                y: {
                                    stacked: true,
                                    ticks: {
                                        precision: 0
                                    },
                                    beginAtZero: true,
                                    grid: {
                                        display: false,
                                    },
                                },
                            },
                            plugins: {
                                title: {
                                    display: false,
                                },
                                legend: {
                                    display: false
                                },
                            },
                        }
                        } />
                </Card>
            </div>
        </>
    );
};

export default MeetingCountGraph