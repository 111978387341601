import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import logo from '../../images/logo-rambuilders.svg'
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import { Col, Row } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import TopBar from './TopBar';
import { enqueueSnackbar } from 'notistack';
import TopBar1 from './TopBar1';

// var key = config.PASSWORD_ENCRYPTION_KEY
// var simpleCrypto = new SimpleCrypto(key)

const VerifyOtp = () => {
    const navigate = useNavigate();
    const location =useLocation()
    const queryParams = new URLSearchParams(location.search)
    const [email,setEmail]=useState()
    const [otp, setOtp] = useState('');
    const { companyname, id: uid } = useParams();

    useEffect(() => {
        setEmail(queryParams.get('email'))
        console.log("email",queryParams.get('email'))
    }, [])

    const myComponentStyle = {
        width: '30px',
        height: '38px',
        color: 'black',
        border: '1px solid black',
        paddingBottom: 2,
        borderRadius: '6px',
        marginLeft: '1px',
        marginRight: '1px',
        fontWeight: 'bold',
        fontSize: '20px',
    }
    const verifyOtp = () => {
        let headers = {
            "Content-Type": 'application/json',
            // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "companyName": companyname,
            "otp": otp,
            "email": email,
            "code": uid
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/verifyOtp', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message, payload);
                    otpVerified()
                } else {
                    console.log("Error", payload);
                    enqueueSnackbar('Incorrect otp!', { variant: 'error' })
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                enqueueSnackbar('Incorrect otp!', { variant: 'error' })
                navigate("*")
            });
    };
    const sendOtp = () => {
        let headers = {
            "Content-Type": 'application/json',
            // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        var payload = {
            "companyName": companyname,
            "email": email,
            "code": uid
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'companyAdmin/sendOtp', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    enqueueSnackbar('Otp sent successfully!', { variant: 'success' })
                    console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error, payload);
                navigate("*")
            });
    };
    const otpVerified = () => {
        navigate('/otpverified')
    }
    return (
        <section className="flex flex-col">
            <TopBar1 />
            <Row style={{ width: "100%" }}>
                <Col md={4}></Col>
                <Col md={4} className='mt-[25vh] border-2 rounded-xl'>
                    <div className="text-center md:text-left">
                        <label className="mt-4 mr-1 text-lg text-black-600">Verify OTP</label>
                    </div>
                    <div className='mt-6 flex items-center text-center justify-center'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputType='number'
                            inputStyle={myComponentStyle}
                            renderSeparator={<span> &nbsp; </span>}
                            renderInput={(props) => <input {...props} />}
                        />
                    </div>
                    <div id="status" className="errormsg"></div>

                    <div className="flex justify-end text-center md:text-left">
                        <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2 font-semibold uppercase rounded text-xs" type="submit" onClick={verifyOtp}>Verify</button>
                    </div>
                    <center>  <p className='p-4'>Did not get the OTP? <span onClick={sendOtp} className='text-cyan-700 cursor-pointer'> Resend OTP</span></p></center>

                </Col>
            </Row>
        </section>
    )
}

export default VerifyOtp;