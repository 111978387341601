import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import companydetails from '../../images/companydetails-r1.svg';
import companyadmindetails from '../../images/companyadmindetails-r2.svg';
import successregistration from '../../images/successregistration-r3.svg';
import verify from '../../images/verifyotp-r4.svg';
import otpverified from '../../images/otpverified-r5.svg';

export default function RegisterProcess({ showPopup, closePopup }) {
    const [section, setSection] = useState(0);
    const [totalSections, setTotalSections] = useState(5);

    useEffect(() => {
        let interval = setInterval(() => {
            let newSection = (section + 1) % totalSections;
            setSection(newSection);
        }, 10000);
        return () => clearInterval(interval);
    }, [section]);

    return (
        <Modal
            show={showPopup}
            size={window.innerWidth <= 768 ? 'sm' : 'xl'}
            onHide={closePopup}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="mx-2 md:h-[78vh] h-[100vh]">
               
                        <div className='md:px-4 px-1 w-full flex items-center justify-between'>
                            <div className='text-xl text-gray-800 font-semibold invisible'></div>
                            <div className='text-xl text-gray-800 font-semibold md:ml-1 -ml-8'>Registration Process</div>
                            <div> <i onClick={closePopup} className="cursor-pointer fa fa-times" aria-hidden="true"></i></div>
                        </div>
                    <div className="ml-3 mr-3 my-3 sec-display">
                        {[...Array(totalSections).keys()].map((index) => (
                            <div
                                key={index}
                                className={`sec-color`}
                                onClick={() => setSection(index)}
                            >
                                <div className={`${section === index ? 'bg-color' : ''}`}></div>
                            </div>
                        ))}
                    </div>
                    {section === 0 ?
                        // <div class="flex w-full h-full flex-col md:flex-row gap-4 items-center justify-center">
                        //     <div class="w-1/2 p-3 mt-[35vh] h-[70vh]">
                        //         <p className='text-left text-2xl font-semibold'>Step 1</p>
                        //         <p className='mt-2 text-left text-base'>This is the first screen to enter details about your business.</p>
                        //         <p className='mt-2 text-left text-base'>For each field you are given additional information in the next window.</p>
                        //     </div>
                        //     <div class="w-1/2 p-4 flex-1 ">
                        //         <img src={companydetails} className='w-[60%] h-[60vh]' />
                        //     </div>
                        // </div>
                        <div class="flex flex-col md:flex-row w-full h-full gap-4 items-center justify-center">
                            <div class="w-full md:w-1/2 p-3 md:mt-[35vh] mt-[1vh] md:h-[70vh]">
                                <p className='text-left text-2xl font-semibold'>Step 1</p>
                                <p className='mt-2 text-left text-base'>This is the first screen to enter details about your business.</p>
                                <p className='mt-2 text-left text-base'>For each field, you are given additional information in the next window.</p>
                            </div>
                            <div class="w-full md:w-1/2 p-4 flex-1">
                                <img src={companydetails} className='w-full md:w-[60%] md:h-[60vh] h-[55vh]' />
                            </div>
                        </div>
                        : section === 1 ?
                            // <div class="flex w-full h-full flex-col md:flex-row gap-4 items-center justify-center">
                            //     <div class="w-1/2 p-3 mt-[35vh] h-[70vh]">
                            //         <p className='text-left text-2xl font-semibold'>Step 2</p>
                            //         <p className='mt-2 text-left text-base'>This is the next screen to enter details of the person who is the key decision maker of your business, it can be yourself.</p>
                            //         <p className='mt-2 text-left text-base'>Provide correct email and phone number as they are important to validate the user in further steps.</p>
                            //     </div>
                            //     <div class="w-1/2 p-3 flex-1 ">
                            //         <img src={companyadmindetails} className='w-2/3 h-[40vh]' />
                            //     </div>
                            // </div>
                            <div class="flex flex-col md:flex-row w-full h-full gap-4 items-center justify-center">
                                <div class="w-full md:w-1/2 p-3 md:mt-[35vh] mt-[1vh] md:h-[70vh]">
                                    <p className='text-left text-2xl font-semibold'>Step 2</p>
                                    <p className='mt-2 text-left text-base'>This is the next screen to enter details of the person who is the key decision maker of your business, it can be yourself.</p>
                                    <p className='mt-2 text-left text-base'>Provide correct email and phone number as they are important to validate the user in further steps.</p>
                                </div>
                                <div class="w-full md:w-1/2 p-4 flex-1">
                                    <img src={companyadmindetails} className='w-full' />
                                </div>
                            </div>
                            : section === 2 ?
                                // <div class="flex w-full h-full flex-col md:flex-row gap-4 items-center justify-center">
                                //     <div class="w-1/2 p-3 mt-[35vh] h-[70vh]">
                                //         <p className='text-left text-2xl font-semibold'>Step 3</p>
                                //         <p className='mt-2 text-left text-base'>Your registeration process is successfully completed!</p>
                                //         <p className='mt-2 text-left text-base'>Please follow next step of validating with OTP which you will receive an email provided in the earlier step.</p>
                                //     </div>
                                //     <div class="w-1/2 p-3 flex-1 ">
                                //         <img src={successregistration} className='w-full' />
                                //     </div>
                                // </div>
                                <div class="flex flex-col md:flex-row w-full h-full gap-4 items-center justify-center">
                                    <div class="w-full md:w-1/2 p-3 md:mt-[35vh] mt-[1vh] md:h-[70vh]">
                                        <p className='text-left text-2xl font-semibold'>Step 3</p>
                                        <p className='mt-2 text-left text-base'>Your registeration process is successfully completed!</p>
                                        <p className='mt-2 text-left text-base'>Please follow next step of validating with OTP which you will receive an email provided in the earlier step.</p>
                                    </div>
                                    <div class="w-full md:w-1/2 p-4 flex-1">
                                        <img src={successregistration} className='w-full' />
                                    </div>
                                </div>
                                : section === 3 ?
                                    // <div class="flex w-full h-full flex-col md:flex-row gap-4 items-center justify-center">
                                    //     <div class="w-1/2 p-3 mt-[35vh] h-[70vh]">
                                    //         <p className='text-left text-2xl font-semibold'>Step 4</p>
                                    //         <p className='mt-2 text-left text-base'>You will receive an OTP and verification link on your mail given at the time of registration.</p>
                                    //         <p className='mt-2 text-left text-base'>Click on the verification link and enter correct OTP.</p>
                                    //     </div>
                                    //     <div class="w-1/2 p-3 flex-1 ">
                                    //         <img src={verify} className='w-full' />
                                    //     </div>
                                    // </div>
                                    <div class="flex flex-col md:flex-row w-full h-full gap-4 items-center justify-center">
                                        <div class="w-full md:w-1/2 p-3 md:mt-[35vh] mt-[1vh] md:h-[70vh]">
                                            <p className='text-left text-2xl font-semibold'>Step 4</p>
                                            <p className='mt-2 text-left text-base'>You will receive an OTP and verification link on your mail given at the time of registration.</p>
                                            <p className='mt-2 text-left text-base'>Click on the verification link and enter correct OTP.</p>
                                        </div>
                                        <div class="w-full md:w-1/2 p-4 flex-1">
                                            <img src={verify} className='w-full' />
                                        </div>
                                    </div>
                                    :
                                    // <div class="flex w-full h-full flex-col md:flex-row gap-4 items-center justify-center">
                                    //     <div class="w-1/2 p-3 mt-[35vh] h-[70vh]">
                                    //         <p className='text-left text-2xl font-semibold'>Step 5</p>
                                    //         <p className='mt-2 text-left text-base'>After OTP verified successfully, you are completed onboarding to <b>"Entropi platform"</b></p>
                                    //         <p className='mt-2 text-left text-base'>You can start using the benefits!!</p>
                                    //     </div>
                                    //     <div class="w-1/2 p-3 flex-1 ">
                                    //         <img src={otpverified} className='w-full' />
                                    //     </div>
                                    // </div>
                                    <div class="flex flex-col md:flex-row w-full h-full gap-4 items-center justify-center">
                                        <div class="w-full md:w-1/2 p-3 md:mt-[35vh] mt-[1vh] md:h-[70vh]">
                                            <p className='text-left text-2xl font-semibold'>Step 5</p>
                                            <p className='mt-2 text-left text-base'>After OTP verified successfully, you are completed onboarding to <b>"Entropi platform"</b></p>
                                            <p className='mt-2 text-left text-base'>You can start using the benefits!!</p>
                                        </div>
                                        <div class="w-full md:w-1/2 p-4 flex-1">
                                            <img src={otpverified} className='w-full' />
                                        </div>
                                    </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}
