import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import EditEvent from './EditCalendarEvent';
import { config } from '../../Config/Config';
import axios from 'axios';
import "./Calendar.css"
import SimpleCrypto from "simple-crypto-js";
import ViewDetail from './ViewDetails';
import moment from 'moment-timezone';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import logo from "../../images/logo-rambuilders.svg";
import threadality from '../../images/threadality-logo.svg';
import NewMeeting from './NewMeeting';
import { enqueueSnackbar } from 'notistack';
import business from '../../images/business-meet.svg';
import personal from '../../images/personal-meet.svg';
import self from '../../images/self-meet.svg';
import MeetingCountGraph from './MeetingCountGraph';
import PageNotFound from "../../LandingPage/PageNotFound";
import linkExpired from "../../images/link-expired.svg";



var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const Calendar = ({ employee, role, employees }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [currentEvents, setCurrentEvents] = useState([]);
    const [data, setData] = useState()
    const [actionStatus, setActionStatus] = useState([]);
    const [actionTypes, setActionTypes] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [editDashboardDetails, setEditDashboardDetails] = useState(false);
    const [initialEvents, setInitialEvents] = useState([]);
    const [viewDashboardDetails, setViewDashboardDetails] = useState(false);
    const [queryEmail, setQueryEmail] = useState('');
    const [queryEdit, setQueryEdit] = useState(false);
    const [newMeetingPopup, setNewMeetingPopup] = useState(false);
    const [newMeetingPopupData, setNewMeetingPopupData] = useState(null);
    const [meetingsData, setMeetingsData] = useState([]);
    const [meetingTypes, setMeetingTypes] = useState([]);
    const [statusArr, setStatusArr] = useState([]);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [reasonFilter, setReasonFilter] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [empDetails, setEmpDetails] = useState(null);
    const [empDetailsError, setEmpDetailsError] = useState(false);
    const [validRange, setValidRange] = useState({ start: "", end: "" });
    const [newMeetUrl, setNewMeetUrl] = useState('');
    const [bookingExp, setBookingExp] = useState(false);

    let email = queryParams.get("email");
    let edit = queryParams.get("edit");
    let employeeId = queryParams.get("assign_to")?.toUpperCase();
    let companyCode = queryParams.get("seq");
    let bookingId = queryParams.get("meeting_id");

    useEffect(() => {
        setQueryEmail(email);
        setQueryEdit(edit);
        getEmpDetails();
        let new_meet_url = `${window.location.origin}/newmeeting?edit=true&assign_to=${queryParams.get("assign_to")}&seq=${queryParams.get("seq")}`
        setNewMeetUrl(new_meet_url)
        const timezoneLabel = 'IST';
        const timeGridAxis = document.querySelector('.fc-timegrid-axis-frame');
        timeGridAxis.classList.add("mr-2")
        timeGridAxis.textContent = timezoneLabel;
    }, [])
    useEffect(() => {
        if (empDetails || bookingId)
            getAdminMeetingSchedules();
    }, [empDetails])
    useEffect(() => {
        addNotification();
    }, [initialEvents])
    const addNotification = () => {
        if ('Notification' in window) {
            Notification.requestPermission()
                .then(permission => {
                    if (permission === 'granted') {
                        console.log('Granted')
                        navigator.serviceWorker.ready
                            .then(registration => {
                                return registration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey: config.publicKey
                                });
                            })
                            .then(subscription => {
                                console.log('Push Subscription:', subscription);
                                let headers = {
                                    "Content-Type": 'application/json',
                                    "applicationType": "web"
                                }
                                let payload = {
                                    "code": companyCode,
                                    "employeeId": employeeId,
                                    "bookingId": bookingId,
                                    "endpoint": JSON.stringify(subscription)
                                }
                                axios.post(config.apiUrl + 'manager/updateNotificationEndpoint', payload, { headers: headers })
                                    .then(response => {
                                        console.log("notification endpoints updated succesfully", response.data);
                                    }).catch(error => {
                                        console.error('Error loading Admins Meetings: ', error);
                                    });
                            })
                            .catch(error => {
                                console.error('Error subscribing to push notifications:', error);
                            });
                    }
                });
        }
    }
    const getAdminMeetingSchedules = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        let payload = {
            "newMeet": edit,
            "code": companyCode,
            "employeeId": employeeId,
            "bookingId": bookingId
        }
        console.log("adm meeting payload", payload);
        axios.post(config.apiUrl + 'manager/getAdminMeetingSchedules', payload, { headers: headers })
            .then(response => {
                console.log("admins meetings fetched successfully", response.data);
                setMeetingsData(response.data.allMeetings);
                setStatusArr(response.data.meetingStatus);
                getInitialEvents(response.data.allMeetings);
                let curMeetingTypes = response.data.meetingReasons
                if (edit) {
                    curMeetingTypes = curMeetingTypes.filter(m => m.id !== 3)
                }
                setMeetingTypes(curMeetingTypes);
                setValidRange(response.data.validRange)
                setBookingExp(response.data.expired);
            }).catch(error => {
                console.error('Error loading Admins Meetings: ', error);
                setEmpDetailsError(true);
            });
    }
    const getEmpDetails = () => {
        if (bookingId) return;
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        let payload = {
            "code": companyCode,
            "employeeId": employeeId
        }
        axios.post(config.apiUrl + 'manager/getEmployeeDetails', payload, { headers: headers })
            .then(response => {
                console.log("admins details fetched successfully", response.data);
                setEmpDetails(response.data.empDetails);

            }).catch(error => {
                console.error('Error loading Admins Meetings: ', error);
                setEmpDetailsError(true);
            });
    }

    const handleDateSelect = (selectInfo) => {
        if (bookingId) return;
        console.log("sleect", selectInfo);
        const isAvailable = initialEvents.every((event) => {
            const eventStart = event.start;
            const eventEnd = event.end;
            const selectedStart = selectInfo.start;
            const selectedEnd = selectInfo.end;

            return (
                selectedStart >= eventEnd || selectedEnd <= eventStart
            );
        });
        const today = new Date();
        const oneMonthFromToday = new Date();
        oneMonthFromToday.setMonth(oneMonthFromToday.getMonth() + 1);
        let validDate = new Date(selectInfo.startStr) >= today;
        let dateRange = !edit ? true : new Date(selectInfo.startStr) <= oneMonthFromToday;
        console.log("sslsl", isAvailable, validDate, dateRange)
        if (isAvailable && validDate && dateRange) {
            setNewMeetingPopup(!newMeetingPopup);
            setNewMeetingPopupData(selectInfo);
        } else {
            if (!dateRange)
                enqueueSnackbar("Choose a date within 1 month of span", { variant: "warning" })
            else enqueueSnackbar("Selected time slot is not available", { variant: "warning" });
        }
    }
    const toggleNewMeetingPopup = (data = {}) => {
        setNewMeetingPopup(!newMeetingPopup);
        setNewMeetingPopupData(data);
    }
    const getInitialEvents = (events) => {
        if (edit) {
            events = events.filter(event => event.meeting_status !== 5)
        }
        let curEvents = events.map(event => {
            let endTime = new Date(event?.end_time);
            let startTime = new Date(event?.start_time);
            let state = parseInt(event.meeting_status);
            const colorCode = state === 1 ? "#EFAF52" : state === 2 ? "#65B169" : state === 3 ? "#A992CF"
                : state === 4 ? "#808080" : state === 5 ? "#D87171" : "#000000";
            return (
                {
                    id: event?.id,
                    title: event?.title,
                    start: startTime,
                    end: endTime,
                    data: event,
                    backgroundColor: edit ? "black" : colorCode,
                    borderColor: "inherit",
                    duration: '00:30',
                    reason: event.meeting_reason,
                    organiser: event.organisers_name,
                    attendee: event.attendees_name,
                    status: state
                }
            )
        })
        setInitialEvents([...curEvents])
        let filter_events = JSON.parse(JSON.stringify(curEvents));
        filter_events = filter_events.filter(event => event.status !== 5);
        setFilteredEvents(filter_events)
    }
    const handleEventClick = (clickInfo) => {
        let completedStatusArray = [3, 4];
        let currentStatus = clickInfo.event._def.extendedProps.data.statusId;
        if (bookingId)
            toggleAdminPopup(!viewDashboardDetails);
        else if (!edit)
            togglePopup(!editDashboardDetails);
        setData(clickInfo.event._def.extendedProps.data);
    }
    const handleEvents = (events) => {
        setCurrentEvents(events)
    };
    const getReason = (id) => {
        let curReason = meetingTypes && meetingTypes.find(data => data.id === id);
        return curReason?.reason
    };
    const renderEventContent = (eventInfo) => {
        let meetType = eventInfo?.event?._def?.extendedProps?.reason;
        let meetTitle = eventInfo?.event?._def?.extendedProps?.data?.title;
        let data = eventInfo?.event?._def?.extendedProps?.data;
        if (edit && !bookingId) {
            return (
                <div className={`flex items-center justify-center`}>
                    <p>Slot Booked</p>
                </div>
            )
        } else {
            return (
                <OverlayTrigger placement="top"
                    overlay={<Tooltip>
                        <p>Organiser - {data?.organisers_name}</p>
                        <p>Meeting Type - {getReason(data?.meeting_reason)}</p>
                        <p>Title - {data?.title}</p>
                        <p>Description - {data?.description}</p>
                    </Tooltip>}>
                    <div className={`flex cursor-pointer items-start rounded-xl gap-2 ${eventInfo.event._def.extendedProps.data.statusId === 3 || eventInfo.event._def.extendedProps.data.statusId === 4 ? "line-through" : ""}`}>
                        <img src={meetType === 1 ? business : meetType === 2 ? personal : self} alt='' className='w-4 h-4' />
                        <p>{meetTitle}</p>
                    </div>
                </OverlayTrigger>
            )
        }
    }

    const toggleAdminPopup = (data) => {
        setViewDashboardDetails(!viewDashboardDetails)
        setData(data)
    }
    const togglePopup = (data) => {
        setEditDashboardDetails(!editDashboardDetails)
        setData(data)
    }
    const changeDayHeader = (args) => {
        return moment(args.date).format('ddd DD/MM');
    }
    const resetFilter = () => {
        let filter_events = JSON.parse(JSON.stringify(initialEvents));
        filter_events = filter_events.filter(event => event.status !== 5);
        setFilteredEvents(filter_events);
        setReasonFilter('');
        setSearchQuery('');
        setStatusFilter('');
    }
    const filterByReason = (id) => {
        let curData = initialEvents;
        if (searchQuery) {
            curData = curData && curData.filter(data => data.attendee.toLowerCase().includes(searchQuery.toLowerCase()) ||
                data.organiser.toLowerCase().includes(searchQuery.toLowerCase()));
        }
        if (statusFilter) {
            curData = curData && curData.filter(data => data.status == statusFilter);
            if (statusFilter != 5) {
                curData = curData && curData.filter(data => data.status != 5);
            }
        }
        let filteredData = curData && curData.filter(data => data.reason == id);
        setFilteredEvents(filteredData);
    }
    const filterByName = () => {
        let curData = initialEvents;
        if (reasonFilter) {
            curData && curData.filter(data => data.reason == reasonFilter);
        }
        if (statusFilter) {
            curData = curData && curData.filter(data => data.status == statusFilter);
            if (statusFilter != 5) {
                curData = curData && curData.filter(data => data.status != 5);
            }
        }
        let filteredData = curData && curData.filter(data => data.attendee.toLowerCase().includes(searchQuery.toLowerCase()) ||
            data.organiser.toLowerCase().includes(searchQuery.toLowerCase()));
        setFilteredEvents(filteredData);
    };
    const filterByStatus = (id) => {
        let curData = initialEvents;
        if (searchQuery) {
            curData = curData && curData.filter(data => data.attendee.toLowerCase().includes(searchQuery.toLowerCase()) ||
                data.organiser.toLowerCase().includes(searchQuery.toLowerCase()));
        }
        if (reasonFilter) {
            curData = curData && curData.filter(data => data.reason == reasonFilter);
        }
        let filteredData = curData && curData.filter(data => data.status == id);
        if (id != 5) {
            filteredData = filteredData && filteredData.filter(data => data.status != 5);
        }
        setFilteredEvents(filteredData);
    }
    const handleCopyClick = (url) => {
        const input = document.createElement('input');
        input.setAttribute('value', url);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        enqueueSnackbar("Copied to clipboard!", { variant: 'success' })
    };
    if (empDetailsError) {
        return (<PageNotFound />)
    }
    if (bookingExp) {
        return (<>
            <div className="sticky top-0 z-50 flex min-w-[100vw] h-12 items-center justify-left" style={{ color: "black", background: "#ebf1ff" }}>
                <div className='justify-left items-center ml-3 w-12 cursor-pointer'><Link to="/"><img src={logo} /></Link></div>
                <div className="md:text-2xl sm:text-2xl font-semibold w-[100%] text-center items-center">Entropi - Lead and Deal Management</div>
                <div className='justify-right items-center mr-4 w-28 sm:w-20 cursor-pointer'><img src={threadality} /></div>
            </div>
            <div className='w-full h-[80vh] flex flex-col items-center justify-center'>
                <img src={linkExpired} alt='' className='w-64 h-64' />
                <p className='text-lg font-medium'>This meeting link has already expired</p>
            </div>
        </>)
    }
    return (
        <>
            <div className="sticky top-0 z-50 flex min-w-[100vw] h-12 items-center justify-left" style={{ color: "black", background: "#ebf1ff" }}>
                <div className='justify-left items-center ml-3 w-12 cursor-pointer'><Link to="/"><img src={logo} /></Link></div>
                <div className="md:text-2xl sm:text-2xl font-semibold w-[100%] text-center items-center">Entropi - Lead and Deal Management</div>
                <div className='justify-right items-center mr-4 w-28 sm:w-20 cursor-pointer'><img src={threadality} /></div>
            </div>
            <div className='flex relative'>
                {!edit && !bookingId && <div className='min-w-[20vw] max-w-[20vw] flex flex-col border-2 border-black min-h-[93.5vh] py-4 px-3'>
                    <div className='border-[1px] border-black px-2 py-3 rounded'>
                        <div className='flex justify-between items-center'>
                            <h4 className='font-medium text-2xl'>Filters</h4>
                        </div>
                        <div className='flex flex-col items-center gap-3 mt-2'>
                            <input
                                className='text-sm w-full p-1 border-[1px] border-black rounded-md'
                                type="text"
                                placeholder="Enter name to search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button className='bg-custom-bg-color text-custom-text-color text-xs rounded px-1 h-6 uppercase ml-auto'
                                onClick={filterByName}>Search</button>
                        </div>
                        <div className="flex flex-col gap-2 text-balck font-medium mt-2" >
                            <select name="status" value={reasonFilter} className="text-sm p-2 border border-solid border-gray-300 rounded"
                                onChange={(e) => { setReasonFilter(e.target.value); filterByReason(e.target.value) }} >
                                <option value="">Filter by type</option>
                                {meetingTypes && meetingTypes.map(data => {
                                    return (
                                        <option key={data.id} value={data.id}>{data.reason}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="flex flex-col gap-2 text-balck font-medium mt-2" >
                            <select name="status" value={statusFilter} className="text-sm p-2 border border-solid border-gray-300 rounded"
                                onChange={(e) => { setStatusFilter(e.target.value); filterByStatus(e.target.value) }} >
                                <option value="">Filter by status</option>
                                {statusArr && statusArr.map(data => {
                                    return (
                                        <option key={data.id} value={data.id}>{data.status}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='mt-2 flex justify-end'>
                            <button className='bg-custom-bg-color text-custom-text-color text-xs rounded px-1 h-6 uppercase'
                                onClick={resetFilter}>Reset</button>
                        </div>
                    </div>
                    <div>
                        <MeetingCountGraph empDetails={empDetails} />
                    </div>
                </div>}
                {empDetails &&
                    <div className={`absolute text-center top-[4%] ${edit ? "left-[40%]" : "left-[50%]"} flex gap-4`}>
                        <p className={`text-center font-medium text-2xl`}>{empDetails ? `${empDetails.name}'s Calendar` : ""}</p>
                        {!edit && <button className={`text-center bg-custom-bg-color text-custom-text-color px-3  py-1.5 font-semibold uppercase rounded text-sm`}
                            onClick={() => handleCopyClick(newMeetUrl)}>COPY</button>}
                    </div>}
                <div className='demo-app-main py-4 px-3'>
                    {editDashboardDetails && <EditEvent closePopup={togglePopup} data={data} meetingTypes={meetingTypes} statusArr={statusArr} getAllMeetings={getAdminMeetingSchedules} empDetails={empDetails} />}
                    {viewDashboardDetails && <ViewDetail closePopup={toggleAdminPopup} actionStatus={actionStatus} data={data} meetingTypes={meetingTypes} statusArr={statusArr} empDetails={empDetails} />}
                    {newMeetingPopup && <NewMeeting togglePopup={toggleNewMeetingPopup} data={newMeetingPopupData} meetingTypes={meetingTypes} queryEmail={!edit} getAllMeetings={getAdminMeetingSchedules} empDetails={empDetails} />}
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}

                        headerToolbar={{
                            start: 'title',
                            center: '',
                            end: 'prev,next'
                        }}
                        initialView='timeGridWeek'
                        allDaySlot={false}
                        weekends={true}
                        contentHeight='auto'
                        slotMinTime='08:00:00'
                        slotMaxTime='20:00:00'
                        initialEvents={initialEvents} // alternatively, use the `events` setting to fetch from a feed
                        events={filteredEvents}
                        selectable={true}
                        select={(data) => handleDateSelect(data)}
                        eventContent={renderEventContent} // custom render function
                        eventClick={handleEventClick}
                        eventsSet={handleEvents}
                        dayHeaderFormat={changeDayHeader}
                        validRange={validRange}
                        navLinks={false}
                    />
                </div>
            </div>
            {(!edit || bookingId) &&
                <div className={`flex items-center max-w-max mx-auto gap-4 text-sm mt-1 absolute left-[24vw] bottom-1`}>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#65B169] rounded-xl'></div><span>Accepted</span></div>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#EFAF52] rounded-xl'></div><span>Pending</span></div>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#D87171] rounded-xl'></div><span>Rejected</span></div>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#A992CF] rounded-xl'></div><span>Attended</span></div>
                    <div className='flex gap-3 items-center'><div className='w-3 h-3 bg-[#808080] rounded-xl'></div><span>Skipped</span></div>
                    <div className='flex gap-1 items-center'><img className='w-4 h-4' src={business} alt='Official' /><span>Official</span></div>
                    <div className='flex gap-1 items-center'><img className='w-4 h-4' src={personal} alt='Personal' /><span>Personal</span></div>
                    <div className='flex gap-1 items-center'><img className='w-4 h-4' src={self} alt='Self' /><span>Self</span></div>
                </div>}
        </>
    )
}

export default Calendar