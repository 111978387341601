import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { config } from '../../Config/Config';
import SimpleCrypto from 'simple-crypto-js';
import RegisterCompany from './RegisterCompany';
import { Col, OverlayTrigger, Row } from 'react-bootstrap';
import logo from '../../images/companylogo.svg';
import businessIcon from '../../images/business-modal.svg';
import industryIcon from '../../images/industry-svgrepo-com.svg';
import userIcon from '../../images/clientele.svg';
import sectorsIcon from '../../images/sectors.svg';
import lIcon from '../../images/location-svgrepo-com.svg';
import cityIcon from '../../images/city-svgrepo-com.svg';
import countryIcon from '../../images/country.svg';
import taxIcon from '../../images/tax-svgrepo-com.svg';
import registerIcon from '../../images/companyregistration.svg';
import categoryIcon from '../../images/paymentcategory.svg';
import nameIcon from '../../images/id-card-svgrepo-com.svg';
import empIcon from '../../images/employee-id-svgrepo-com.svg';
import mobileIcon from '../../images/mobile-svgrepo-com.svg';
import emailIcon from '../../images/email-svgrepo-com.svg';
import termsIcon from '../../images/terms-svgrepo-com.svg';
import AdminDetails from './AdminDetails';
import TopBar from './TopBar';
import TermsAndConditions from './TermsAndConditions';
import TopBar1 from './TopBar1';
import RegisterProcess from './RegisterProcess';

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const MultiRegisterForm = (props) => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [companyTypes, setCompanyTypes] = useState([])
    const [companyName, setCompanyName] = useState('');
    const [category, setCategory] = useState('');
    const [userType, setUserType] = useState();
    const [selectedUserType, setSelectedUserType] = useState();
    const [typeOfBusiness, setTypeOfBusiness] = useState();
    const [selectedBusinessType, setSelectedBusinessType] = useState()
    const [pan, setPan] = useState('');
    const [registrationNo, setRegistrationNo] = useState('');
    const [status, setStatus] = useState(1);
    const [companyCode, setCompanyCode] = useState('HO-01')
    const [selectedDuration, setSelectedDuration] = useState()
    const [city, setCity] = useState()
    const [location, setLocation] = useState()
    const [country, setCountry] = useState()
    const [image, setImage] = useState()
    const [durations, setDurations] = useState([])
    const [userLimits, setUserLimits] = useState([])
    const [errors, setErrors] = useState({});
    const [companySizes, setCompanySizes] = useState([]);
    const [selectedSize, setSelectedSize] = useState();
    const [industryTypes, setIndustryTypes] = useState([]);
    const [selectedBT, setSelectedBT] = useState()
    const [branchLimit, setBranchLimit] = useState()
    const [employeeLimit, setEmployeesLimit] = useState()
    const [customerLimit, setCustomersLimit] = useState()
    const [productLimit, setProductsLimit] = useState()
    const [randomText, setRandomText] = useState()
    const [showTerms, setShowTerms] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [paidType, setPaidType] = useState([
        { "id": 1, type: "Basic" },
        { "id": 2, type: "Smart" },
        { "id": 3, type: "Complete" }
    ]);
    const imgRef = useRef()
    const [inputValues, setInputValues] = useState({
        companyCode: 'HO-01',
        companyName: '',
        pan: '',
        registrationNo: '',
        city: '',
        location: '',
        industryType: '',
        typeOfBusiness: '',
        userType: '',
        duration: '',
        category: '',
        image: '',
        checked: false,
        // selectedBT: '',
        // selectedBusinessType: '',
        // selectedDuration: '',
        companySize: '',
        country: '',
        status: '',
        employeeId: '',
        firstName: '',
        lastName: '',
        email: '',
        mobile: ''
    });
    const handleFocus = (event) => {
        const randomTextValue = generateRandomText(event.target.name);
        setRandomText(randomTextValue);
    }
    const generateRandomText = (name) => {
        const randomTexts = {
            companyCode: "Company Code",
            companyName: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Company Name</div><center><img src={logo} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>Enter your company legal name. This is mandatory and is used to identify your entity.</p>
                    <p className='my-3'>Upload your company logo.</p><i>Note that this logo will be used as part of Entropi landing page.</i></div></div>,
            industryType: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Industry Type</div><center><img src={industryIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>By knowing your industry type, we can configure suggested fields for collecting customer information. </p>
                    <p className='my-3'>Each industry requirement of customer details varies from one to another.</p></div></div>,
            typeOfBusiness: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Type of Business</div><center><img src={businessIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>This is important to know about your company specialisation tags and focus on building partnerships </p>
                    <p className='my-3'>It will give Entropi an idea to serve you better by adding new features, services and collaborate to your business growth</p></div></div>,
            companySize: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Sector Type</div><center><img src={sectorsIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>This information will give Entropi platform an idea to understand your business function further and to serve seamlessly where necessary.</p>
                    <p className='my-3'>Entropi adds additional services on its platform which can be suitable to your business to grow</p></div></div>,
            userType: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Clientele</div><center><img src={userIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>Your business can deal with <b>individuals</b> who can take calls immediately or <b>company representative</b> who need to follow procurement process for taking calls</p>
                </div></div>,
            location: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Location</div><center><img src={lIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>Enter your business Head Office location. </p>
                    <p className='my-3'>This will help us for any postal communication.</p></div></div>,
            city: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">City</div><center><img src={cityIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>Enter your business Head Office location. </p>
                    <p className='my-3'>This will help us for any postal communication.</p></div></div>,
            country: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Country</div><center><img src={countryIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>Enter your business Head Office Country. Note that all branches are by default assigned this country. </p>
                    <p className='my-3'>This will help us for any postal communication. </p></div></div>,
            pan: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Tax Number</div><center><img src={taxIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>Provide tax registration number of your company for any tax invoicing from Threadality. This is optional. </p>
                    <p className='my-3'><i>This data is validated with relevant sources and “Verified” mark is assigned</i></p></div></div>,
            registrationNo: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Company Registration</div><center><img src={registerIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>Provide registration number of your company for any reference by Threadality. This is optional. </p>
                    <p className='my-3'><i>This data is validated with relevant sources and “Verified” mark is assigned</i></p></div></div>,
            category: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Category</div><center><img src={categoryIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>Choose 'Free tier' to understand the potential of Entropi but with limited features. It works with {branchLimit} branches, {productLimit} Products, {employeeLimit} Employees and {customerLimit} Customers. </p>
                    <p className='my-3'>To get unlimited features of Entropi upgrade to "Paid tier". Contact sales at <b>{config.email}</b></p></div></div>,
            employeeId: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Employee Id</div><center><img src={empIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
                <div className='py-4 px-4'><p>To identify Company Admin and register to Entropi this is required.</p>
                    <p className='my-3'>This is the user id which is essential for logging into Entropi.</p></div></div>,
             firstName: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">First Name</div><center><img src={nameIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
             <div className='py-4 px-4'><p>Company Admin first name is required to display on top-right corner after logging in.</p>
                 </div></div>,
            lastName: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Last Name</div><center><img src={nameIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
            <div className='py-4 px-4'><p>Company Admin last name is required to display on top-right corner after logging in.</p>
                </div></div>,
            email: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Email</div><center><img src={emailIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
            <div className='py-4 px-4'><p>Company Admin's email address is mandatory for sending mails and facilitating further communication from the Entropi team.</p>
                </div></div>,
            mobile:  <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Mobile</div><center><img src={mobileIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
            <div className='py-4 px-4'><p>Company Admin's mobile number is used for any communication from the Entropi team.</p><p className='my-3'>When providing the phone number, please include country code. If not specified, default country code is +91.</p>
                </div></div>,
            duration: <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Duration</div><center><img src={empIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
            <div className='py-4 px-4'><p>For Paid tier Contact Sales {config.email}</p>
                </div></div>,
            checked:  <div> <div className="flex w-full h-12 rounded-t-xl items-center justify-left text-lg bg-custom-bg-color hover:bg-custom-bg-color text-center justify-center text-custom-text-color">Terms & Conditions</div><center><img src={termsIcon} alt="" className='md:w-16 w-12 pt-4' /></center>
            <div className='py-4 px-4'><p>Please ensure you go through all terms and conditions before you sign up and have seamless experience.</p>
                </div></div>,
        };
        return randomTexts[name] || '';
    };
    useEffect(() => {
        getAllDurationsAndCompanySizes();
        getAllUserTypes();
        const randomTextValue = generateRandomText('companyName');
        setRandomText(randomTextValue);
    }, [])
    useEffect(() => {
        // let currentValues = { ...props.inputValues };
        // setInputValues({ ...props.inputValues, ...currentValues })
    }, []);

    // useEffect(() => {
    //   //  getCustActivities();
    // }, [activityId])

    const getAllDurationsAndCompanySizes = () => {
        let headers = {
            "Content-Type": 'application/json',
            // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllDurations', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setDurations(response.data.duration);
                    setCompanySizes(response.data.sectorTypes);
                    setIndustryTypes(response.data.industryTypes)
                    setCompanyTypes(response.data.typeOfBusiness)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const getAllUserTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            // "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem("random")),
            "applicationType": "web"
        }
        axios.get(config.apiUrl + 'companyAdmin/getAllUserTypes', { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status == true) {
                    console.log("success", response.data.message);
                    setUserType(response.data.userTypes)
                    setUserLimits(response.data.userLimits)
                    setBranchLimit(response.data.userLimits[0].branchesAllowed)
                    setProductsLimit(response.data.userLimits[0].productsAllowed)
                    setEmployeesLimit(response.data.userLimits[0].employeesAllowed)
                    setCustomersLimit(response.data.userLimits[0].customersAllowed)
                } else {
                    console.log("Error");
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };
    const handleChangeCategory = (e) => {
        setCategory(e.target.value);
    }
    const handleChangeBusinessType = (e) => {
        setSelectedBusinessType(e.target.value);
    }
    const handleChangeBT = (e) => {
        setSelectedBT(e.target.value);
    }
    const handleChangeDuration = (e) => {
        setSelectedDuration(e.target.value);
    }
    const handleChangeUserType = (e) => {
        setSelectedUserType(e.target.value);
    }
    const nextStep = () => {
        setStep(step => step + 1)
    }

    const prevStep = () => {
        setStep(step => step - 1)
    }
    const handleChange = (event) => {
        setInputValues({ ...inputValues, [event.target.name]: event.target.value });
    }
    const handleCheck = (event) => {
        setInputValues({ ...inputValues, [event.target.name]: !inputValues.checked });
    }
    const displayTerms = () => {
        setShowTerms(!showTerms)
    }
    const uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "entropi_")
        data.append("cloud_name", "entropi")
        const resp = await fetch(config.uploadUrl, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        console.log("res", res)
        return res.secure_url
    }

    const handleImage = async (e) => {
        console.log("fgh", e.target.files)
        if (e.target.files && e.target.files[0]) {
            const res = await uploadImage(e.target.files[0]);
            if (res) {
                console.log("res", res)
                setInputValues({ ...inputValues, image: res })
            }
        }
    }
    const toggleRegisterProcess = () => {
        setShowPopup(!showPopup);
    }
    return (
        <div>
            {showPopup && <RegisterProcess showPopup={showPopup} setShowPopup={setShowPopup} closePopup={toggleRegisterProcess} />}
            <TopBar1 />
            {showTerms ?
                <Row>
                    <Col md={12}>
                        <TermsAndConditions terms={showTerms} displayTerms={displayTerms} />
                    </Col>
                </Row>
                :
                <>
                    {step === 1 ? (
                        <div className="flex flex-col items-center justify-center gap-4 mt-2 md:flex-row md:ml-[20vw] md:gap-20 min-h-[90vh]">
                            <RegisterCompany
                                nextStep={nextStep}
                                prevStep={prevStep}
                                handleChange={handleChange}
                                handleChangeCategory={handleChangeCategory}
                                handleChangeBusinessType={handleChangeBusinessType}
                                handleChangeDuration={handleChangeDuration}
                                handleChangeUserType={handleChangeUserType}
                                handleChangeBT={handleChangeBT}
                                handleFocus={handleFocus}
                                randomtext={randomText}
                                setRandomText={setRandomText}
                                handleImage={handleImage}
                                generateRandomText={generateRandomText}
                                inputValues={inputValues}
                                companyTypes={companyTypes}
                                durations={durations}
                                industryTypes={industryTypes}
                                companySizes={companySizes}
                                selectedUserType={selectedUserType}
                                selectedDuration={selectedDuration}
                                selectedBusinessType={selectedBusinessType}
                                selectedBT={selectedBT}
                                selectedSize={selectedSize}
                                paidType={paidType}
                                setInputValues={setInputValues}
                                userType={userType}
                                errors={errors}
                                setErrors={setErrors}
                            />
                            <div className="md:w-[27vw] mx-2 md:mt-0 mt-4">
                                <center>
                                    <div className="flex items-center justify-center">
                                        Need Help?
                                        <button
                                            className="ml-1 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 font-semibold uppercase rounded text-xs"
                                            onClick={toggleRegisterProcess}
                                        >
                                            Click here
                                        </button>
                                    </div>
                                </center>
                                <div className="mt-5 md:whitespace-pre-line md:w-full w-[94vw] h-96 border-[1px] border-black rounded-xl">
                                    {/* <center>     <img src={randomImage} alt="" className='md:w-20 w-12 py-4' /></center> */}
                                    {randomText}
                                </div>
                            </div>
                        </div>
                    ) : step === 2 ? (
                        <div className="flex flex-col items-center justify-center gap-4 mt-2 md:flex-row md:ml-[20vw] md:gap-20 min-h-[90vh]">
                            <AdminDetails
                                displayTerms={displayTerms}
                                nextStep={nextStep}
                                prevStep={prevStep}
                                randomText={randomText}
                                setRandomText={setRandomText}
                                handleCheck={handleCheck}
                                generateRandomText={generateRandomText}
                                inputValues={inputValues}
                                errors={errors}
                                setErrors={setErrors}
                                handleChange={handleChange}
                                handleFocus={handleFocus}
                            />
                            <div className="md:w-[27vw] mx-2 md:mt-0 mt-4">
                                <center>
                                    <div className="flex items-center justify-center">
                                        Need Help?
                                        <button
                                            className="ml-1 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color p-2 font-semibold uppercase rounded text-xs"
                                            onClick={toggleRegisterProcess}
                                        >
                                            Click here
                                        </button>
                                    </div>
                                </center>
                                <div className="mt-5 md:w-full w-[94vw] h-96 border-[1px] border-black  rounded-xl">
                                    {/* <center>     <img src={randomImage} alt="" className='md:w-20 w-12 py-4' /></center> */}
                                    {randomText}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            }
        </div>
    )
}

export default MultiRegisterForm;