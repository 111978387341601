import React from "react";
import '../components/Register/Terms.css';
import error from '../images/error-404.svg'
import TopBar from "../components/Register/TopBar";
import { useNavigate } from "react-router-dom";
import TopBar1 from "../components/Register/TopBar1";

export default function PageNotFound() {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1)
    }
    return (
        <div>
            <TopBar1 />
            <div className="px-6">
                <p className="terms-heading"><i className="fa fa-long-arrow-left cursor-pointer" onClick={handleBack} aria-hidden="true"></i></p>
                <center className="my-20">
                    <img src={error} className="w-[20%]"/>
                    <div className="heading">Oops!</div>
                    <p className="terms_Text">Page not found</p>
                    <p className="terms_Text">...maybe the page you’re looking for is not found or never existed.</p>
                </center>
            </div>
        </div >
    )
}

