import React, { useState } from "react";
import './Terms.css';
import { Card, Accordion,Row, Col } from "react-bootstrap";
import TopBar from "./TopBar";
import { useNavigate } from "react-router-dom";
import TopBar1 from "./TopBar1";

// export default function FAQ() {
//     const navigate = useNavigate();
//     const handleBack = () => {
//         navigate("/")
//     }
//     return (
//         <div>
//             <TopBar />
//             <div className="px-6">
//                 <p className="terms-heading"><i className="fa fa-long-arrow-left cursor-pointer" onClick={handleBack} aria-hidden="true"></i> &nbsp;FAQ</p>
//                 <div className="mt-3 ml-4 mr-4">
//                     <div className="heading">Your Information is safe</div>
//                     <p className="terms_Text">You’re responsible for your use of the Services and any content you provide, including compliance with applicable laws. Content on the
//                         Services may be protected by others’ intellectual property rights. Please don’t copy, upload, download, or share content unless you
//                         have the right to do so.</p>
//                 </div>
//             </div>
//         </div >
//     )
// }


export default function FAQ() {

    const [data, setData] = useState([
        {
            id: 1,
            ques: "How to start using Entropi?",
            ans: "Kindly proceed with the registration on Entropi by utilizing the provided link: 'https://www.entropi.work'."
        },
        {
            id: 2,
            ques: "How do I connect with entropi?",
            ans: "For additional information, please feel free to send an email to elie@entropi.work, and the Entropi team will promptly connect with you."
        },
        {
            id: 3,
            ques: "Should I need any special hardware to install?",
            ans: "No, this is a cloud based SaaS application designed for Small, Medium, Soloprenuers."
        },
        {
            id: 4,
            ques: "How many types of categories are there and what are they?",
            ans: "Two types of categories are there. Free and Paid."
        },
        {
            id: 5,
            ques: "What are features provided for free user?",
            ans: "Features for free users include the ability to add up to two branches, three products, four employees, and ten customers. The platform offers comprehensive functionalities, encompassing product insights, customer experience, expectation management, lead management, deals management, as well as performance tracking for both employees and branches."
        },
        {
            id: 6,
            ques: "Can I transfer to paid user without loosing my data?",
            ans: "Yes. You can transfer to paid user without loosing your data."
        },
        {
            id: 7,
            ques: "What are the security measures followed?",
            ans: "Client and company data are securely encrypted and stored to safeguard against unauthorized access. Additionally, passwords are hashed for enhanced security measures. Furthermore, the system restricts users from logging in on multiple devices simultaneously, allowing access on only one device at a time."
        },
        {
            id: 8,
            ques: "Where can I download mobile app?",
            ans: "Upon successful completion of the registration process and OTP verification, a confirmation email will be sent to the user's registered email address. Subsequently, the user may proceed to download the mobile application using the provided mobile app link."
        },
        {
            id: 9,
            ques: "How do I raise any issues with this platform?",
            ans: "To raise any platform-related issues, please log in using company admin credentials."
        },
    ])
    const navigate = useNavigate();
    const handleBack = () => {
        navigate("/")
    }
    const handleAddIcon = (index) => {
        let newData = [...data];
        newData = newData.map((obj, id) => {
            return {
                ...obj,
                open: id === index
            }
        })
        setData(newData)
    }

    const handleCloseIcon = () => {
        let newData = [...data];
        newData = newData.map((obj, id) => {
            return {
                ...obj,
                open: false
            }
        })
        setData(newData)
    }

    return (
        <div>
            <TopBar1 />
            <div className="px-6">
                <p className="terms-heading"><i className="fa fa-long-arrow-left cursor-pointer" onClick={handleBack} aria-hidden="true"></i> &nbsp;FAQ</p>
            </div>
            <Row style={{ width: "100%" }} className="mt-10">
                <Col md={2} sm={1} xs={1}></Col>
                <Col md={8} xs={10}>
                    {data.map((obj, id) =>
                        obj.open ? <>
                            <div className='wrap_'>
                                <div>
                                    <p className="cursor-pointer mt-1" style={{ textDecoration: "none", fontSize: "18px", color: "black" }} onClick={handleCloseIcon} >{obj.ques}</p></div>
                                <div> <i className="fa fa-minus cursor-pointer" style={{ color: "black" }} onClick={() => handleCloseIcon(id)} aria-hidden="true"></i></div>
                            </div>
                            <p style={{ fontSize: "14px", color: "black", padding: "10px 0" }}>{obj.ans}</p>
                            <p style={{ marginTop: "5px", marginBottom: "5px" }}>  <hr /></p>
                        </>
                            :
                            <>
                                <div className='wrap_'>
                                    <div>
                                        <p className="cursor-pointer mt-1" style={{ textDecoration: "none", fontSize: "18px", color: "black" }} onClick={() => handleAddIcon(id)}>{obj.ques}</p></div>
                                    <div><i className="fa fa-plus cursor-pointer" style={{ color: "black" }} aria-hidden="true" onClick={() => handleAddIcon(id)}></i></div></div>
                                <p style={{ marginTop: "5px", marginBottom: "5px" }}>  <hr /></p>
                            </>
                    )}
                </Col>
                <Col md={2} sm={1} xs={1}></Col>
            </Row>
            {/* <div className="mt-10">
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>How to start using Entropi?</Accordion.Header>
                        <Accordion.Body>
                            Register on Entropi using this link 'https://www.entropi.work'.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Should I need any special hardware to install?</Accordion.Header>
                        <Accordion.Body>
                            No, this is a cloud based SaaS application.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div> */}
                {/* <Accordion defaultActiveKey="0">
                        <Card>
                            <Accordion.Toggle as={Card.Header} style={{ color: "#31197C" }} eventKey="0">
                                <a style={{ color: "black", textDecoration: "none" }}> &nbsp;>How to start using Entropi?</a> <span style={{ float: "right", color: "#31197C" }}><i className="fa fa-plus angleright" aria-hidden="true"></i></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>         Register on Entropi using this link 'https://www.entropi.work'.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion> */}
                  
        </div>
    )
}


