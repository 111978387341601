import { createEvent } from 'ics';

export const generateICSFile = (meetingDetails) => {
    const event = {
        start: [meetingDetails.startYear, meetingDetails.startMonth, meetingDetails.startDay, meetingDetails.startHour, meetingDetails.startMinute],
        end: [meetingDetails.endYear, meetingDetails.endMonth, meetingDetails.endDay, meetingDetails.endHour, meetingDetails.endMinute],
        title: meetingDetails.title,
        description: meetingDetails.description,
        location: meetingDetails.location,
    };
    const { error, value } = createEvent(event);
    if (error) {
        console.error('Error creating ICS file:', error);
        return null;
    }
    return value;
}

export const downloadICSFile = (icsContent, fileName) => {
    const blob = new Blob([icsContent], { type: 'text/calendar' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}