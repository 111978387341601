import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SimpleCrypto from "simple-crypto-js";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { config } from "../../Config/Config";
import moment from "moment-timezone";
import { enqueueSnackbar } from "notistack";
import download from "../../images/download-icon.svg";
import { downloadICSFile, generateICSFile } from "./utils";

var key = config.PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const NewMeeting = ({ data, togglePopup, meetingTypes, queryEmail, getAllMeetings, empDetails }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedReason, setSelectedReason] = useState('')
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [newMeet, setNewMeet] = useState(null);

    useEffect(() => {
        console.log("data", data);
    }, []);

    const addNewMeeting = () => {
        if (validateField()) {
            enqueueSnackbar("Please fill all the required fields", { variant: 'error' });
            return;
        }
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }
        var payload = {
            "startTime": data.startStr,
            "endTime": data.endStr,
            "organisersName": queryEmail ? empDetails.name : name,
            "organisersEmail": queryEmail ? empDetails.email : email,
            "attendeesName": queryEmail ? name : empDetails.name,
            "attendeesEmail": queryEmail ? email : empDetails.email,
            "title": title,
            "description": description,
            "reason": selectedReason ? parseInt(selectedReason) : null,
            "meetingStatus": 2,
            "empId": empDetails.id
        }
        console.log("payload", payload)
        axios.post(config.apiUrl + 'manager/addNewMeeting', payload, { headers: headers })
            .then(function (response) {
                console.log("success meeting added succesfully", response.data);
                getAllMeetings();
                if (queryEmail) togglePopup();
                else setNewMeet(response.data.newMeeting);
            })
            .catch(function (error) {
                let errorCode = error?.response?.status;
                if (errorCode === 400) {
                    enqueueSnackbar("Slot booked already", { variant: "error" });
                    getAllMeetings();
                }
                console.log("error", error, payload);
                togglePopup();
            });
    };
    const dateFormatter = (date) => {
        if (!date) return "";
        let formattedDate = moment(date).format("DD/MM/YYYY hh:mma");
        return formattedDate
    }
    const validateField = () => {
        let error = false;
        let errorObj = {};
        if (!name) {
            errorObj = {
                ...errorObj,
                nameError: true
            }
            error = true;
        }
        if (!email) {
            errorObj = {
                ...errorObj,
                emailError: true
            }
            error = true;
        }
        if (!title) {
            errorObj = {
                ...errorObj,
                titleError: true
            }
            error = true;
        }
        if (!selectedReason) {
            errorObj = {
                ...errorObj,
                reasonError: true
            }
            error = true;
        }

        setErrors({ ...errorObj });
        return error;
    }
    const handleMeetingTypeSelect = (e) => {
        let curValue = e.target.value;
        setSelectedReason(curValue);
        setErrors({ ...errors, reasonError: false });
        if (curValue == 3) {
            setEmail(empDetails?.email);
            setName(empDetails?.name);
        } else if (curValue !== 3 && selectedReason == 3) {
            setEmail('');
            setName('');
        }
    };
    const handleCopyClick = (url) => {
        const input = document.createElement('input');
        input.setAttribute('value', url);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        enqueueSnackbar("Copied to clipboard!", { variant: 'success' })
    };
    const handleDownloadICS = (data) => {
        let startTime = new Date(data.start_time);
        let endTime = new Date(data.end_time);
        const meetingDetails = {
            startYear: startTime.getFullYear(),
            startMonth: startTime.getMonth() + 1,
            startDay: startTime.getDate(),
            startHour: startTime.getHours(),
            startMinute: startTime.getMinutes(),
            endYear: endTime.getFullYear(),
            endMonth: endTime.getMonth() + 1,
            endDay: endTime.getDate(),
            endHour: endTime.getHours(),
            endMinute: endTime.getMinutes(),
            title: data.title,
            description: data.description,
            location: 'Online-Web Meet',
        };
        const icsContent = generateICSFile(meetingDetails);

        if (icsContent) {
            downloadICSFile(icsContent, 'meeting.ics');
        }
    };
    return (
        <div>
            <Modal
                show={true}
                onHide={togglePopup}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {newMeet ?
                    <div className=" max-w-sm m-2 mr-2 mx-auto my-4">
                        <div className="flex items-center w-full justify-between">
                            <label className="mr-1 text-lg text-black-600 text-end font-medium">New Meeting Added Successfully</label>
                            <OverlayTrigger placement="right"
                                overlay={<Tooltip>
                                    <p>Download ICS file</p>
                                </Tooltip>}>
                                <img src={download} alt="" className="w-6 h-6 cursor-pointer -mt-2" onClick={() => handleDownloadICS(newMeet)} />
                            </OverlayTrigger>
                        </div>
                        <p className="text-sm my-2">Click on this link to view the status of the meeting</p>
                        <a className='text-blue-600 underline underline-offset-1 -mt-4' target='_blank'
                            href={`${window.location.origin}${location.pathname}?meeting_id=${newMeet.booking_id}`}>
                            {`${window.location.origin}${location.pathname}?meeting_id=${newMeet.booking_id}`}
                        </a>
                        <button className='w-10 float-right bg-custom-bg-color text-custom-text-color p-1 font-semibold uppercase rounded text-[10px]'
                            onClick={() => handleCopyClick(`${window.location.origin}${location.pathname}?meeting_id=${newMeet.booking_id}`)}>COPY</button>
                    </div>
                    : <div className=" max-w-sm m-2 mr-2 mx-auto mb-4">
                        <div className="text-center md:text-left my-3">
                            <label className="mr-1 text-lg text-black-600">Set up a new meeting</label>
                        </div>
                        <div className="flex flex-col w-full">
                            <label className="text-sm text-black-600">Reason For the Meeting</label>
                            <select name="reason" value={selectedReason} className={`text-sm p-2 ${errors && errors.reasonError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded`}
                                onChange={(e) => handleMeetingTypeSelect(e)} >
                                <option value="">Select Reason</option>
                                {meetingTypes && meetingTypes.map(data => {
                                    return (
                                        <option key={data.id} value={data.id}>{data.reason}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <label className="mr-1 text-sm text-black-600 mt-2">Name</label>
                        <input className={`text-sm w-full px-4 py-2 rounded ${errors && errors.nameError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"}`}
                            value={name}
                            onChange={(e) => { setName(e.target.value); setErrors({ ...errors, nameError: false }) }}
                            placeholder={queryEmail ? "Attendees Name" : "Organisers Name"} />
                        <label className="mr-1 text-sm text-black-600 mt-2">Email</label>
                        <input className={`text-sm w-full px-4 py-2 rounded ${errors && errors.emailError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"}`}
                            value={email}
                            onChange={(e) => { setEmail(e.target.value); setErrors({ ...errors, emailError: false }) }}
                            placeholder={queryEmail ? "Attendees Email" : "Organisers Email"} />
                        <label className="mr-1 text-sm text-black-600 mt-2">Start Time</label>
                        <p className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded">{dateFormatter(data.startStr)}</p>
                        <label className="mr-1 text-sm text-black-600 mt-2">End Time</label>
                        <p className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded">{dateFormatter(data.endStr)}</p>
                        <label className="mr-1 text-sm text-black-600 mt-2">Title</label>
                        <textarea className={`text-sm w-full p-2 ${errors && errors.titleError ? "border-[1px] border-red-600" : "border-[1px] border-gray-300"} rounded resize-none`}
                            type="text"
                            onChange={(e) => { setTitle(e.target.value); setErrors({ ...errors, titleError: false }) }}
                            name="title"
                            value={title}
                            placeholder="Enter a title for the meeting"
                            rows={1}
                            maxLength={40}
                        />
                        <label className="mr-1 text-sm text-black-600 mt-2">Description</label>
                        <textarea className="text-sm w-full p-2 border border-solid border-gray-300 rounded resize-none"
                            type="text"
                            onChange={(e) => setDescription(e.target.value)}
                            name="description"
                            value={description}
                            placeholder="Decribe the meeting agenda"
                            rows={2}
                            maxLength={75}
                        />
                        <div className="flex justify-between text-center md:text-left">
                            <button className="mt-4 bg-orange-600 hover:bg-orange-700 px-4 py-2 text-white font-semibold uppercase rounded text-xs" type="submit" onClick={togglePopup}>Back</button>
                            <button className="mt-4 bg-custom-bg-color hover:bg-custom-bg-color text-custom-text-color px-4 py-2  font-semibold uppercase rounded text-xs" onClick={addNewMeeting} type="submit">Create</button>
                        </div>
                    </div>}
            </Modal>
        </div>
    )
}

export default NewMeeting